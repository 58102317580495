import {maxiGet} from "../core/maxios";
import {downloadAsPdf} from "../core/download";
import {Container, Loader, MaxBtn} from "../core/components";
import React, {useState} from "react";
import Status from "../core/status";

export function ExamCertificate({match}) {
    const {examstart_ID} = match.params;
    const [{error, loading}, setStatusVar] = useState({})
    return <Container name={"Zertifikat herunterladen"}>
        <Status text={error} type={"error"}/>
        <Loader loading={loading}/>

        <MaxBtn onClick={() => maxiGet(`/exam/examstart/${examstart_ID}/certificate`, {setStatusVar}).then(({content, exam}) => downloadAsPdf(content, exam.name + ".pdf"))}>
            herunterladen
        </MaxBtn>
    </Container>
}
import {Container, Loader, MaxBtn, MaxLink} from "../core/components";
import React, {useEffect, useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {ExamTypeMap} from "../core/enums";
import {useHistory} from "react-router";

export function ExamstartStart({match}) {
    const {exam_ID} = match.params;
    const history = useHistory();
    const [{error, loading}, setStatusVar] = useState({})
    const [exam, setExam] = useState()
    const loadExamstart = () => maxiGet(`/exam/${exam_ID}`, {setStatusVar}).then(setExam)
    useMemo(loadExamstart, [exam_ID]);

    return <Container name={exam?.name}>
        <Status text={error} type={"error"}/>

        <Loader loading={loading}/>

        {
            exam && (exam.type.value === ExamTypeMap.selfservice ? <>
                Leg dir vorab dein Turn10-Heft bereit, das du zum Nachlesen jederzeit verwenden kannst. Solltest du es digital verwenden, ist es einfacher, wenn du die Prüfung auf einem Gerät bearbeitest und die Turn10-Unterlagen auf einem anderen ansiehst.
                <br/>
                Wenn du die Prüfung starten möchtest, klicke auf folgenden Knopf. Bitte beachte, dass die für die Prüfung verfügbaren 40 Minuten Bearbeitungszeit damit sofort zu laufen beginnen.


                <br/>

                <MaxBtn onClick={() => maxiPost(`/exam/${exam_ID}/examstart`, {}, {setStatusVar}).then(examstart => {
                    history.push(`/prüfungsantritt/${examstart.ID}/0`)
                })}>
                    Prüfung starten
                </MaxBtn>

            </> : <>
                <HasExamStarted exam_ID={exam_ID}/>
            </>)
        }

    </Container>
}


function HasExamStarted({exam_ID}) {
    const [{loading, error}, setStatusVar] = useState({})
    const [examstart, setExamstart] = useState(null)

    const load = () => maxiGet(`/exam/${exam_ID}/examstart`, {setStatusVar}).then(resp => resp.examstart && setExamstart(resp.examstart))

    useEffect(() => {
        load();
        const timer = setInterval(load, 5000)
        return () => clearInterval(timer)
    }, []);
    return <>
        <Status type={"error"} text={error}/>

        {
            examstart ? <>
                Die Prüfung wurde vom Prüfungsleiter bereits gestartet. Drücke auf folgenden Knopf um die Prüfung zu öffnen:<br/>
                <MaxLink to={`/prüfungsantritt/${examstart.ID}/0`}>
                    Prüfung öffnen
                </MaxLink>
            </> : <>
                Die Prüfung wurde noch nicht gestartet, warte hier um an der Prüfung teilzunehmen.
            </>
        }
    </>
}
import React, {useMemo, useState} from "react";
import {DateInput, LightContainer, MyModal, MyReactTable, TextfieldInput} from "../core/input_fields";
import {AddressPrinterFull} from "../core/helpers";
import {LicenceKindLabelMap} from "../core/enums";
import {dateFormat} from "../core/dateFuncs";
import styles from "./Licence.module.sass"
import {InfoTooltip, Loader, MaxBtn, MaxLink} from "../core/components"
import {FormContextWrapper} from "../core/form_context";
import {FaEdit, FaPlus} from "react-icons/all";
import {maxiDelete, maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/userHistory";

export function LicencePrinter({person, licences, user, simple}) {

    return <>
        {
            person && <LightContainer name={person.fullname}>
                <p>Lizenznummer: <b>{person.licenceNumber}</b>{!person.licenceNumber && <>noch keine vorhanden <InfoTooltip>wird bei Erstantrag automatisch vergeben</InfoTooltip></>}</p>
                <p>E-Mail: {person.email}</p>
                <p>Telefon: {person.phone > 0 && person.phone}</p>
                <p>
                    <AddressPrinterFull {...person}/>
                </p>
                <h2>Gültige Lizenzen:</h2>

                {
                    licences.map(licence => <div className={styles.qualification}>
                        <h3>{LicenceKindLabelMap[licence.kind.value]} {licence.noQualifications && "(Übergangslizenz)"}</h3>
                        <h4>Sparte: {licence.sector.label}</h4>
                        <p>
                            Gültig von {dateFormat(licence.timeApproved)} bis <b>{dateFormat(licence.validTill)}</b>
                        </p>
                        {
                            user && !simple && <MaxLink to={"/lizenz/beantragen/" + licence.kind?.value + "/" + licence.sector?.value}>Verlängerung beantragen</MaxLink>
                        }
                    </div>)
                }

                {
                    licences.length === 0 && "Keine"
                }

            </LightContainer>
        }
        {
            person && user && <JudgeAppearanceList person_ID={person.ID} user={user}/>
        }

    </>
}


export function JudgeAppearanceList({person_ID, user}) {
    const [jas, setJas] = useState([])
    const [{error, loading}, setStatusVar] = useState({})

    const load = () => maxiGet(`/judgeAppearance/${person_ID}`, {setStatusVar}).then(setJas)
    useMemo(load, [person_ID])

    return <>
        <h2>Wertungsrichter-Einsätze</h2>
        <MyReactTable
            data={jas}
            columns={[
                {
                    Header: "Veranstaltung",
                    accessor: "event",
                    filterable: true,
                },
                {
                    Header: "",
                    maxWidth: 25,
                    Cell: ({original}) => <MyModal trigger={<FaEdit/>}>
                        {close => <JudgeAppearanceEdit ja={original} user={user} close={() => {
                            close()
                            load()
                        }}/>}
                    </MyModal>,
                },
                {
                    Header: "Datum",
                    id: "date",
                    maxWidth: 120,
                    accessor: original => dateFormat(new Date(original.date)),
                    filterable: true,
                },
                {
                    Header: "Ort",
                    accessor: "location",
                    filterable: true,
                },
                {
                    Header: "Rolle",
                    accessor: "role",
                    filterable: true,
                },

            ]}
            loading={loading}
        />

        <MyModal trigger={<MaxBtn>Wertungsrichter-Einsatz hinzufügen <FaPlus/></MaxBtn>}>
            {close => <JudgeAppearanceEdit ja={{person_ID}} user={user} close={() => {
                close()
                load()
            }}/>}
        </MyModal>

    </>
}

export function JudgeAppearanceEdit({ja, close, user}) {
    const [state, setState] = useState({...ja});
    const [{error, loading}, setStatusVar] = useState({});

    return <LightContainer name={"Wertungsrichter-Einsatz"}>
        <FormContextWrapper value={{state, setState}} onSubmit={() => maxiPost("/judgeAppearance", state, {setStatusVar}).then(close)}>
            <Status text={error} type={"error"}/>
            <TextfieldInput name={"Veranstaltungs-Titel"} tag={"event"}/><br/>
            <TextfieldInput name={"Veranstaltungs-Ort"} tag={"location"}/><br/>
            <TextfieldInput name={"Rolle"} tag={"role"}/><br/>
            <DateInput name={"Datum"} tag={"date"}/><br/>
            <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
        </FormContextWrapper>
        {
            ja.ID && <MaxBtn onClick={() => window.confirm("Wirklich löschen?") && maxiDelete(`/judgeAppearance/${ja.ID}`, {setStatusVar}).then(close)}>
                Einsatz löschen
            </MaxBtn>
        }
        {
            user.role > 80 && ja.ID && <UserHistory userID={ja.ID} personType={"judgeAppearance"}/>
        }
    </LightContainer>
}

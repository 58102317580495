import React, {useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import {CheckboxInput, LightContainer, MiniBtn, MyModal, MyReactTable} from "../core/input_fields";
import {Container, Loader, MaxBtn} from "../core/components";
import Status from "../core/status";
import {Link} from "react-router-dom";
import {downloadAsExcel, downloadAsPdf} from "../core/download";
import {dateFormat, dateFormatDateInline, dateFormatTime, dateFormatTimeShort} from "../core/dateFuncs";
import {EventKindMap, RegistrationStatusMap} from "../core/enums";
import {encodeGetParams} from "../core/helpers";
import {FaCheck, FaRegStar, FaStar, FaUserEdit, FaUsers} from "react-icons/fa";
import UserHistory from "../user/userHistory";
import {FaDownload} from "react-icons/all";
import {FormContextWrapper} from "../core/form_context";
import {PersonEditWrapper} from "../user/personHelpers";
import {LicencePersons} from "../licence/licencePersons";


export function EventRegistrationList({event}) {

    const [registrations, setRegistrations] = useState([]);
    const [personMode, setPersonMode] = useState(true);
    const [{error, loading, success}, setStatusVar] = useState({});
    const [tableCount, setTableCount] = useState(null);
    const loadRegistration = (per_person) => () => maxiGet(`/registration/per_event/${per_person ? "persons" : "registrations"}/${event.ID}`, {setStatusVar}).then(data => {
        setRegistrations(data.registrations)
        setPersonMode(per_person)
    })
    useMemo(loadRegistration(personMode), []);
    const tableCountLocal = tableCount || [
        registrations.filter(a => a.registration?.status.value === RegistrationStatusMap.finalized && a.registered_person?.role?.value === "official").length,
        registrations.filter(a => a.registration?.status.value === RegistrationStatusMap.finalized && a.registered_person?.role?.value !== "official").length]
    const tableCountTeams = Object.keys(registrations.filter(a => a.registered_person?.team_ID).reduce((obj, curr) => ({...obj, [curr.registered_person?.team_ID]: true}), {})).length
    const tableCountClubs = Object.keys(registrations.filter(a => a.clubs).reduce((obj, curr) => ({...obj, [curr.clubs]: true}), {})).length

    const anyTeam = registrations.some(a => !!a.registered_person?.teamName);

    return <LightContainer name={

        {
            true: `Registrierungen (${tableCountLocal[1]} Teilnehmer / ${tableCountLocal[0]} ${event.allowOfficialsLabel || "Offizielle"}${tableCountTeams > 0 ? " / " + tableCountTeams + " Teams" : ""}${tableCountClubs > 0 ? " / " + tableCountClubs + " Vereine" : ""})`,
            false: `Registrierungen (${tableCountLocal[1]} Registrierungen, ${tableCountClubs} Vereine)`,
        }[personMode]
    } addName>

        <div>

            {
                event.eventStart < new Date() && personMode && registrations.length > 0 && [EventKindMap.ausbildung, EventKindMap.ausbildungwr, EventKindMap.fortbildung].includes(event.kind.value) && <>
                    <MyModal trigger={<MaxBtn>Teilnahme bestätigen</MaxBtn>}>
                        <AddRegistrationAsQualification {...{registrations, event, reload: loadRegistration(personMode)}}/>
                    </MyModal> &nbsp;&nbsp;
                </>
            }
            {
                event?.kind?.value===EventKindMap.pruefung && personMode && <>
                    <MyModal trigger={<MaxBtn>Lizenzen anzeigen</MaxBtn>}>
                        <LicencePersons {...{person_IDs: registrations.map(p => p.person?.ID), event, reload: loadRegistration(personMode)}}/>
                    </MyModal> &nbsp;&nbsp;
                </>
            }


            <MaxBtn onClick={loadRegistration(!personMode)}>
                {personMode ? "Registrierungen" : "Personen"} anzeigen
            </MaxBtn>&nbsp; &nbsp;

            <MaxBtn onClick={() => {
                maxiGet("/registration/per_event_download/" + event.ID, {setStatusVar})
                    .then(({data}) => {
                        downloadAsExcel(data, `Teilnehmer_${event.name}_${dateFormatTime(new Date())}.xlsx`)
                    })

            }}>Angemeldete Personen herunterladen</MaxBtn>

        </div>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={registrations}
            loading={loading}
            defaultSorted={[{id: "person.fullname"}]}
            onTableChange={b => setTableCount([b.filter(a => a._original.registered_person?.role?.value === "official").length, b.filter(a => a._original.registered_person?.role?.value !== "official").length])}
            columns={[
                {
                    Header: "Person",
                    accessor: "person.fullname",
                    show: personMode,
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.person, reload: loadRegistration(personMode)}}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Art",
                    accessor: "registered_person.role.label",
                    show: personMode,
                    filterable: true,
                    Cell: ({value}) => value && value[0] || "",
                    maxWidth: 45,
                },
                {
                    Header: "Mannschaft",
                    show: anyTeam,
                    accessor: "registered_person.teamName",
                    filterable: true,
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.owner, reload: loadRegistration(personMode)}}><em>{value}</em></PersonEditWrapper>

                },
                {
                    Header: "Anzahl an Personen",
                    accessor: "count",
                    show: !personMode,

                },
                {
                    Header: "Vereine",
                    accessor: "clubs",
                    filterable: true,
                },
                {
                    Header: "Abgesendet",
                    //accessor: "registration.timeFinalized",//data => data.registration.timeFinalized ? dateFormatTime(new Date(data.registration.timeFinalized)) : "nicht abgeschickt",
                    id: "timeFinalized",
                    filterable: true,
                    accessor: original => original.registration?.status.value === RegistrationStatusMap.finalized ? dateFormatTimeShort(new Date(original.registration?.timeFinalized)) : "nicht abgeschickt",
                    maxWidth: 150,
                },
                {
                    Header: "",
                    Cell: ({original}) => <>
                        <Link to={"/anmeldungen/" + original.registration.ID}>anschauen</Link>
                        &nbsp;
                        <MyModal trigger={<em>Buchungen anzeigen</em>}>
                            <ShowOffersOfPerson event={event} registration={original.registration} person={original.person}/>
                        </MyModal>
                    </>
                }

            ]}
        />
    </LightContainer>
}


function AssociateQualification({original, reload, event}) {
    const [{loading, error}, setStatusVar] = useState({})
    const [confirmed, setConfirmed] = useState(false);
    const associate = data => maxiPost("/licence/add_event_part", data, {setStatusVar}).then(() => {
        reload()
        setConfirmed(true)
    })
    const download = (ID) => {
        maxiGet("/licence/qualifications/generate_certificate?" + encodeGetParams({registeredPerson_ID: ID}), {setStatusVar}).then(({content, eventName}) => downloadAsPdf(content, eventName + ".pdf"))
    }
    return <>
        {
            !!error && [<MyModal trigger={<MiniBtn style={{backgroundColor: "red"}}>Fehler anzeigen</MiniBtn>}>
                <Status type={"error"} text={error}/>
            </MyModal>, <br/>]
        }

        <em onClick={() => download(original.registered_person.ID)}><FaDownload/></em>
        {
            !loading && !confirmed && <MiniBtn onClick={() => window.confirm("Wirklich bestätigen und E-Mail senden?") && associate({event_ID: event.ID, registeredPerson_ID: original.registered_person.ID, email: true})}>E-Mail</MiniBtn>
        }
        &nbsp;
        {
            !loading && !confirmed && <MiniBtn onClick={() => window.confirm("Wirklich bestätigen und KEIN E-Mail senden?") && associate({event_ID: event.ID, registeredPerson_ID: original.registered_person.ID, email: false})}>kein E-Mail</MiniBtn>
        }
        {
            confirmed && <FaCheck/>
        }
        <Loader loading={loading}/>
    </>
}

function AddRegistrationAsQualification({event, registrations, reload}) {
    const [count, setCount] = useState(registrations.length)
    const [{loading, error}, setStatusVar] = useState({});
    const [persons, setPersons] = useState({});
    const load = () => maxiPost(`/person/similar_to`, {"person_IDs": registrations.map(p => p.person.ID)}, {setStatusVar}).then(setPersons)
    useMemo(load, registrations.map(p => p.person.ID))

    return <Container name={`Teilnahme bestätigen${count !== null ? " (" + count + ")" : ""}`}>
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={registrations}
            onTableChange={a => setCount(a.length)}
            defaultSorted={[{id: "person.fullname"}]}
            columns={[
                {
                    Header: "Person",
                    accessor: "person.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.person}}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Vereine",
                    accessor: "clubs",
                    filterable: true,
                },
                {
                    Header: "Bestätigt",
                    id: "confirmed",
                    maxWidth: 90,
                    accessor: r => r.confirmed ? "ja" : "nein",
                    filterable: true,
                    Cell: ({original, value}) => <MyModal trigger={<em>{value}</em>}>
                        <UserHistory userID={original.registered_person.ID} personType={"registeredPerson"}/>
                    </MyModal>
                },
                {
                    Header: "Vereinen",
                    id: "zusammenführen",
                    filterable: true,
                    maxWidth: 100,
                    accessor: original => persons[original.person.ID] || [],
                    Cell: ({original, value}) => {
                        const taids = Array.from(new Set(value.map(a => a.licenceNumber).filter(b => !!b)));
                        const allHavingSameLicenceNumber = taids.length === 1 && !value.some(a => a.licenceNumber !== taids[0])

                        return <>
                            {original.person.parent_ID ? "U" : "V"}&nbsp;
                            {
                                value.length > 1 && <MyModal trigger={<em><FaUsers/> {value?.length || 0} {allHavingSameLicenceNumber ? <FaCheck style={{color: "green"}}/> : " / " + taids.length}</em>}>
                                    {close => <CombineUsers {...{
                                        ...original, close, reload: () => {
                                            load()
                                            reload()
                                        }, persons: value
                                    }}/>}
                                </MyModal>
                            }
                        </>
                    },
                },
                {
                    Header: "Bestätigen",
                    maxWidth: 210,
                    Cell: ({original}) => <AssociateQualification  {...{original, reload, event, registrations}}/>
                }
            ]}
        />
    </Container>
}

export function CombineUsers({person, persons, clubs, close, reload}) {
    const [{loading, error}, setStatusVar] = useState({});
    const [state, setState] = useState({showSameEmail: true});

    const [selected, setSelected] = useState([person.ID]);
    const [shouldGetVerwalter, setShouldGetVerwalter] = useState(null);


    const personsFiltered = persons.filter(p => !!state.showSameEmail || (p.fullname === person.fullname && p.dateOfBirth === person.dateOfBirth) || p.licenceNumber === person.licenceNumber);
    const personsSelected = persons.filter(p => selected.includes(p.ID));

    const commonalities = {
        Verein: new Set(personsFiltered.map(a => a.clubs)),
        Geburtstag: new Set(personsFiltered.map(a => a.dateOfBirth)),
        Email: new Set(personsFiltered.map(a => a.email)),
    }

    const possibleVerwalter = personsSelected.filter(p => p.parent_ID === null && p.email.trim() !== "");

    return <LightContainer name={`${person.fullname} zusammenführen`}>
        <Status type={"error"} text={error}/>
        {/*Person: {person.fullname}, {person.email ? person.email + ", " : ""}{person.dateOfBirth ? <>{dateFormatDateInline(new Date(person.dateOfBirth))}, </> : ""}{clubs}<br/>

        Turnsport-Austria-ID: {person.licenceNumber}<br/>
        */}

        <FormContextWrapper value={{state, setState}}>
            <CheckboxInput name={"Personen mit nur gleicher E-Mail-Adresse anzeigen"} labelWidth={300} tag={"showSameEmail"}/>
        </FormContextWrapper>

        Gemeinsamkeiten:
        <ul>
            {Object.entries(commonalities)
                .filter(([k, v]) => v.size === 1)
                .map(([k, v]) => <li>
                    <b>{k}:</b> {Array.from(v).join("")}
                </li>)}

        </ul>

        {
            possibleVerwalter.length > 1 && <Status type={"error"} text={"In der Auswahl sind mehrere Verwalter vorhanden."}/>
        }

        <MyReactTable
            data={personsFiltered}
            columns={[
                {
                    Header: "Auswahl",
                    maxWidth: 25,
                    Cell: ({original}) => {
                        const k = original.ID;
                        const checked = selected.includes(k);
                        return <input type={"checkbox"} checked={checked} onClick={() => setSelected(a => checked ? a.filter(b => b !== k) : [...a, k])} style={{marginRight: 0}}/>
                    },
                },
                {
                    Header: "Verwalter",
                    maxWidth: 25,
                    show: possibleVerwalter.length !== 1,
                    Cell: ({original}) => {
                        const k = original.ID;
                        const checked = shouldGetVerwalter === k;
                        const Icon = checked ? FaStar : FaRegStar;
                        return <Icon onClick={() => {
                            if (!original.email.trim()) {
                                alert("Der Verwalter muss eine E-Mail-Adresse haben.")
                                return
                            }
                            setShouldGetVerwalter(checked ? null : k)
                        }} style={{marginRight: 0}}/>
                    },
                },
                {
                    Header: "Name",
                    accessor: "fullname",
                    filterable: true,
                    Cell: ({original, value}) => original.ID === person.ID ? <b>{value}</b> : value,
                },
                {
                    Header: "E-Mail",
                    accessor: "email",
                    show: commonalities.Email.size > 1,
                    filterable: true,
                },
                {
                    Header: "Geburtsdatum",
                    accessor: "dateOfBirth",
                    show: commonalities.Geburtstag.size > 1,
                    Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                    filterable: true,
                },
                {
                    Header: "V",
                    id: "eigenständig",
                    maxWidth: 50,
                    accessor: a => a.parent_ID ? "U" : "V",
                    filterable: true,
                },

                {
                    Header: "TA-ID",
                    accessor: "licenceNumber",
                    show: personsFiltered.some(a => a.licenceNumber),
                    filterable: true,
                },
                {
                    Header: "Vereine",
                    accessor: "clubs",
                    filterable: true,
                    show: commonalities.Verein.size > 1,
                },
                {
                    Header: "Login",
                    id: "login",
                    maxWidth: 120,
                    accessor: a => a.loginLast ? dateFormat(new Date(a.loginLast)) : "",
                    filterable: true,
                },
                {
                    Header: "",
                    id: "edit",
                    maxWidth: 25,
                    Cell: ({original}) => <PersonEditWrapper personLight={original} reload={reload}><FaUserEdit/></PersonEditWrapper>,
                }
            ]}
        />

        <p>
            Gewählte Person ist fett markiert. Falls in der Auswahl kein selbstständiger Benutzer vorhanden ist, muss ein Benutzer ausgewählt, der dupliziert und zum selbstständigen Benutzer (V) gemacht wird.
        </p>

        <MaxBtn onClick={() => maxiPost(`/person/unite`, {"person_IDs": personsSelected.map(p => p.ID), shouldGetVerwalter}, {setStatusVar}).then(reload)}>
            vereinen
        </MaxBtn>
        <Loader loading={loading}/>
    </LightContainer>
}

function ShowOffersOfPerson({event, registration, person}) {
    const [registrations, setRegistrations] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({});
    const loadRegistration = (per_person) => () => maxiGet(`/registration/per_person/${event.ID}/${person.ID}`, {setStatusVar}).then(data => {
        setRegistrations(data.registrations)
    })
    const personMode = true;
    useMemo(loadRegistration(personMode), []);
    const anyTeam = registrations.some(a => !!a.registered_person?.teamName);

    return <LightContainer name={"Optionen für " + (registrations.length > 0 ? registrations[0].person.fullname : "")}>
        <Status type={"error"} text={error}/>
        <Loader loading={loading}/>
        <MyReactTable
            data={registrations}
            loading={loading}
            columns={[
                {
                    Header: "Option",
                    accessor: "offer.description",
                    filterable: true,
                },
                {
                    Header: "Art",
                    accessor: "registered_person.role.label",
                    filterable: true,
                    maxWidth: 100,
                },
                {
                    Header: "Mannschaft",
                    show: anyTeam,
                    accessor: "registered_person.teamName",
                    filterable: true,
                },
                {
                    Header: "Registrant",
                    accessor: "owner.fullname",
                    filterable: true,
                },

                {
                    Header: "Abgesendet",
                    accessor: "registration.timeFinalized",//data => data.registration.timeFinalized ? dateFormatTime(new Date(data.registration.timeFinalized)) : "nicht abgeschickt",
                    id: "timeFinalized",
                    filterable: true,
                    Cell: ({value}) => value ? dateFormatTime(new Date(value)) : "nicht abgeschickt"
                },
                {
                    Header: "",
                    Cell: ({original}) => <>
                        <Link to={"/anmeldungen/" + original.registration.ID}>anschauen</Link>
                    </>
                }

            ]}
        />
    </LightContainer>
}

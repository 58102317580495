import {Container, MaxLink} from "../core/components";
import React, {useContext, useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import {ExamTypeMap} from "../core/enums";
import {UserContext} from "../user/UserContext";

export function ExamList() {

    const {user} = useContext(UserContext)

    const [exams, setExams] = useState()
    const [{error, loading}, setStatusVar] = useState({})
    useMemo(() => maxiGet("/exam", {setStatusVar}).then(setExams), []);

    return <Container name={"Prüfungen"}>
        <Status text={error} type={"error"}/>


        {
            user && exams?.map(exam => <div style={{padding: 10, backgroundColor: "var(--maincolorlight)", borderRadius: 8, marginBottom: 10}}>
                <h3>{exam.name}</h3>

                <MaxLink to={"/prüfungsantritt/" + exam.ID}>Prüfung starten</MaxLink>

                &nbsp;

                {
                    user.role >= 38 ?
                        <MaxLink to={"/prüfungsantritte/" + exam.ID}>Antritte anzeigen</MaxLink> :
                        <MaxLink to={"/prüfungsantritte/" + exam.ID}>Meine Antritte anzeigen</MaxLink>
                }
                &nbsp;
                {
                    user.role >= 38 && <MaxLink to={"/prüfungen/" + exam.ID}>Fragen bearbeiten</MaxLink>
                }
            </div>)
        }


    </Container>
}
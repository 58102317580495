import React, {useContext, useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import {FileUpload, LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {Loader, MaxBtn} from "../core/components";
import Status from "../core/status";
import PersonEdit from "../user/personEdit";
import PersonList from "../user/personList";
import TeamList from "../user/teamList";
import {PersonTypeMap, RegistrationStatusMap} from "../core/enums";
import {UserContext} from "../user/UserContext";
import ShowOffersOfPerson from "../event/eventOffersOfPerson";
import {Link} from "react-router-dom";
import {dateFormat} from "../core/dateFuncs";
import {FormContextWrapper} from "../core/form_context";
import {FaCheck, FaFileUpload, FaUpload} from "react-icons/fa";
import {PersonEditWrapper} from "../user/personHelpers";

const now = new Date();

export function RegisteredPersonsList({event, eventPermitted, registeredPersons, registration, loadRegistration}) {

    const [persons, setPersons] = useState([]);
    const [{error, loading, success}, setStatusVar] = useState({});

    const context = useContext(UserContext);
    const loadPersons = () => context.user?.role < 38 && maxiGet(`/person`, {setStatusVar}).then(data => {
        setPersons(data.persons)
    })

    useMemo(loadPersons, [context.user?.role]);
    const loadRegistrationsAndPersons = () => {
        loadRegistration()
        loadPersons()
    }

    const registeredPersonIds = registeredPersons.map(r => r.person.ID);
    const registeredPersonIdsParticipants = registeredPersons.filter(r => r.registeredPerson.role.value === PersonTypeMap.participant).map(r => r.person.ID);
    const registeredPersonIdsOfficials = registeredPersons.filter(r => r.registeredPerson.role.value === PersonTypeMap.official).map(r => r.person.ID);

    return <LightContainer name={"Personen"}>
        {
            (event.bookingStart < now && now < event.bookingEnd || eventPermitted) && registration.status.value === RegistrationStatusMap.draft &&
            <div style={{float: "right", marginTop: "-60px"}}>
                {
                    [0, 2].includes(event.teamsRequired) && <MyModal onClose={loadRegistration} trigger={<MaxBtn>Teilnehmer hinzufügen</MaxBtn>}>
                        {close => <RegisteredPersonsAdd close={close} personType={PersonTypeMap.participant}
                                                        persons={persons} setPersons={setPersons} personsAvailable={persons.filter(p => registeredPersonIdsParticipants.indexOf(p.ID) === -1).map(p => p.ID)}
                                                        registration={registration} reload={loadRegistrationsAndPersons}/>}
                    </MyModal>
                }
                &nbsp;&nbsp;
                {
                    event.allowOfficials && <><MyModal onClose={loadRegistration} trigger={<MaxBtn>Offiziellen hinzufügen</MaxBtn>}>
                        {close => <RegisteredPersonsAdd close={close} personType={PersonTypeMap.official}
                                                        persons={persons} setPersons={setPersons} personsAvailable={persons.filter(p => registeredPersonIdsOfficials.indexOf(p.ID) === -1).map(p => p.ID)}
                                                        registration={registration} reload={loadRegistrationsAndPersons}/>}
                    </MyModal>

                        &nbsp;&nbsp;
                    </>
                }
                {
                    [1, 2].includes(event.teamsRequired) && <MyModal onClose={loadRegistration} trigger={<MaxBtn>Mannschaft hinzufügen</MaxBtn>}>
                        {close => <RegisteredTeamAdd close={close}
                                                     persons={persons} setPersons={setPersons} personsAvailable={persons.filter(p => registeredPersonIds.indexOf(p.ID) === -1).map(p => p.ID)}
                                                     registration={registration} reload={loadRegistrationsAndPersons}/>}
                    </MyModal>
                }
            </div>
        }
        <Status type={"error"} text={error}/>
        <MyReactTable
            data={registeredPersons}
            loading={loading}
            defaultSorted={[{id: "person.fullname"}]}
            columns={[
                {
                    Header: "Name",
                    accessor: "person.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper reload={() => {
                        loadPersons()
                        loadRegistration()
                    }} personLight={original.person}>
                        <em>{value}</em>
                    </PersonEditWrapper>
                },
                {
                    Header: "Mannschaftsname",
                    accessor: "registeredPerson.teamName",
                    filterable: true,
                    show: registeredPersons.some(({registeredPerson}) => !!registeredPerson.teamName)
                },
                {
                    Header: "Art",
                    accessor: "registeredPerson.role.label",
                    filterable: true,
                },
                {
                    Header: "Teilnahme-Voraussetzung",
                    show: event.any_requirement,
                    id: "requirement",
                    filterable: true,
                    accessor: r => !!r.requirement ? "erfüllt" : (r.registeredPerson.requirementConfirmation !== null ? "Bestätigung" : "-"),
                    Cell: ({original: r}) => (!!r.requirement) ? <MyModal trigger={<em><FaCheck/> erfüllt</em>}>
                            <LightContainer>
                                durch Teilnahme an <Link to={`/veranstaltungen/${r.requirement.ID}`}>{r.requirement.name}</Link> ({dateFormat(new Date(r.requirement.eventEnd))})
                            </LightContainer>
                        </MyModal> :
                        <MyModal trigger={<em> {!!r.registeredPerson.requirementConfirmation ? <><FaCheck/> hochgeladen</> : <b style={{color: "red"}}><FaFileUpload/> hochladen</b>}</em>}>
                            {close => <UploadRequirementConfirmation {...{...r, close, event}}/>}
                        </MyModal>
                },
                {
                    maxWidth: 260,
                    Cell: ({original, value}) => <>
                        {
                            registration.status.value === RegistrationStatusMap.draft && <em onClick={() => {
                                if (window.confirm("Person aus Registrierung entfernen?")) {
                                    maxiPost(`/registration/${registration.ID}/delete_person`, {person_ID: original.person.ID}, {setStatusVar}).then(loadRegistration)
                                }
                            }
                            }>entfernen</em>
                        }
                        &nbsp;
                        <MyModal trigger={<em>Buchungen anzeigen</em>}>
                            <ShowOffersOfPerson event={event} registration={original.registration} person={original.person}/>
                        </MyModal>
                    </>
                }
            ]}
        />
    </LightContainer>
}

function UploadRequirementConfirmation({registeredPerson, person, event, close}) {
    const [state, setState] = useState({a: registeredPerson})
    const [{error, loading}, setStatusVar] = useState({});
    const saveConfirmation = (a, b, c) => maxiPost(`/registration/${registeredPerson.registration_ID}/registered_person/${registeredPerson.ID}/set_confirmation`, {requirementConfirmation: c}, {setStatusVar}).then(close)
    return <FormContextWrapper value={{state, setState}}>
        <LightContainer name={`Teilnahme-Bestätigung von ${person.fullname} hochladen`}>
            <Status type={"error"} text={error}/>
            <FileUpload name={"Bestätigung"} tag={"a_requirementConfirmation"} updateState={saveConfirmation}/>
            <Loader loading={loading}/>
            <br/>
            <br/>
            Entweder lade eine Teilnahmebestätigung einer der folgenden Ausbildungen hoch oder, wenn diese Person bereits ein Basismodul an der Turnsport-Austria-Akademie absolviert hat, hinterlege die Turnsport-Austria-ID bei der Person,
            sodass hier automatisch erkannt wird, dass die Voraussetzungen für diese Veranstaltung erfüllt sind.
        </LightContainer>
    </FormContextWrapper>

}

export function RegisteredPersonsAdd({persons, setPersons, personsAvailable, personType, registration, close, reload}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const handleSubmit = (person) => {
        !loading && maxiPost(`/registration/${registration.ID}/add_person`, {person_ID: person.ID, personType}, {setStatusVar})
            .then(() => {
                reload()
            })
    }
    const personTypeMapDeclined = {[PersonTypeMap.participant]: "Teilnehmer", [PersonTypeMap.official]: "Offiziellen"}; // somehow is too complicated
    return <LightContainer name={personTypeMapDeclined[personType] + " hinzufügen"}>
        <Status type={"error"} text={error}/>
        <PersonList
            persons={persons}
            nameCell={({original, value}) => personsAvailable.includes(original.ID) ? <em onClick={() => {
                handleSubmit(original)
            }}>{value} (hinzufügen)</em> : <>{value} (bereits ausgewählt)</>}
            setPersons={setPersons}
            error={""}
            loadPersons={reload}
            match={{}}
            loading={loading}
        />
    </LightContainer>
}

export function RegisteredTeamAdd({persons, personsAvailable, registration, close, reload}) {
    const [{error, loading, success}, setStatusVar] = useState({});

    const handleSubmit = (team) => {
        !loading && maxiPost(`/registration/${registration.ID}/add_team`, {team_ID: team.ID}, {setStatusVar})
            .then(() => {
                reload()
            })
    }
    return <LightContainer name={"Mannschaft hinzufügen"}>
        <Status type={"error"} text={error}/>
        <TeamList
            persons={persons}
            nameCell={({original, value}) => <em onClick={() => {
                handleSubmit(original)
            }}>{value} (hinzufügen)</em>}
            error={""}
            loadPersons={reload}
            match={{}}
            loading={loading}
        />
    </LightContainer>
}

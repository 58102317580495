import React, {Component, useEffect,} from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";

import {Switch} from "react-router";
import {Container} from "./core/input_fields";
// ie stuff
import "babel-polyfill";
import {polyfill} from 'es6-promise';
import cssVars from 'css-vars-ponyfill';
import {maxiGet} from "./core/maxios";
import {Club, clubMap, domainMap} from "./core/custom";
import {UserContext} from "./user/UserContext";
import ErrorBoundary from "./core/error_boundary";
import ChangePW from "./user/changepw";
import AsyncComponent from "./core/AsyncComponent";

import Nav from "./nav";
import CheckLogin from "./user/check_login";
import Login from "./user/login";

import UserEdit from "./user/userEdit";
import EventList from "./event/eventList";
import EventView from "./event/eventView";
import BillingAddressList from "./booking/billingAddressList";
import PersonTeamView from "./user/personTeamView";
import RegistrationView from "./booking/registrationView";
import RegistrationList from "./booking/registrationList";
import {LicenceFormWrapper} from "./licence/licenceForm";
import LicencePublicQuery from "./licence/licencePublicQuery";
import {LicenceListPage, LicenceListPersonen, LicencePageWrapper} from "./licence/licenceList";
import LicenceQuery from "./licence/licenceQuery";
import LicenceProfile from "./licence/licenceProfile";
import AluminList from "./event/alumniList";
import {ExamList} from "./exam/examList";
import {QuestionList} from "./exam/questionList";
import {Examstart} from "./exam/examstart";
import {Examresult} from "./exam/examresult";
import {ExamstartStart} from "./exam/examstart_start";
import {ExamstartList} from "./exam/examstart_list";
import {ExamresultAll} from "./exam/examresultAll";
import {LicencePersons} from "./licence/licencePersons";
import {ExamCertificate} from "./exam/examCertificate";

polyfill();

// end ie stuff
function ScrollToTop({location}) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);
    return null;
}

class App extends Component {
    state = {
        navOpen: localStorage.getItem("mainContainer") === "true",
        navToggle: () => {
            localStorage.setItem("mainContainer", !this.state.navOpen);
            this.setState({navOpen: !this.state.navOpen})
        },
        mounted: false,
        club: (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club),
        clubs: JSON.parse(localStorage.getItem("clubs") || "{}"),
    };

    addUserFromStatus = resp => {
        !!resp.club && this.setCssVars(resp.club);
        this.setState(resp);
    };

    setCssVars = club => cssVars({
        rootElement: document,
        variables: {
            "mainColor": club.color,
            "maincolorlight": club.colorLight,
            "fontColor": club.fontColor || "#444",
            "fontColorInverse": club.fontColorInverse || "#fff",
        },
    });

    componentWillMount() {
        const club = (!!localStorage.getItem("vereinID_cache") ? domainMap[clubMap[(parseInt(localStorage.getItem("verein_ID_cache")))]] : Club);
        this.setCssVars(club)
    }

    componentDidMount = () => {
        this.clubsLoad()
    };

    clubsLoad = () => {
        if (localStorage.getItem("clubsUpdated") === null || (new Date()).getTime() - localStorage.getItem("clubsUpdated") > 1000 * 60 * 30) {
            maxiGet("/clubs").then(({clubs}) => {
                    const clubsMap = clubs.reduce((obj, curr) => ({...obj, [curr[0]]: {ID: curr[0], name: curr[1]}}), {})
                    localStorage.setItem("clubsUpdated", (new Date()).getTime());
                    localStorage.setItem("clubs", JSON.stringify(clubsMap));
                    this.setState({clubs: clubsMap})
                }
            )
        }

    };

    render() {

        return (
            <Router>

                <UserContext.Provider value={this.state}>
                    <div style={{
                        position: "fixed",
                        bottom: 0,
                        right: 0,
                        fontSize: "10px",
                        backgroundColor: "white",
                        padding: "5px"
                    }}>
                        version {window.vatomatorVersion}
                    </div>
                    <div className={"App " + (window.location.hostname === "localhost" ? "blue" : "tvg")}>
                        <Route path="/" render={(props) => <CheckLogin {...props} addUserFromStatus={this.addUserFromStatus}/>}/>
                        {
                            /*!!this.state.user && this.state.user.status === "logged_in" &&
                             */
                        }
                        <Route path="/" component={Nav}/>
                        <Route path="/" component={ScrollToTop}/>
                        <MainContainer>
                            <div id={"mainOverlayForNav"}/>
                            <ErrorBoundary key={"ljljfs"}>
                                <Switch>
                                    <Route exact path="/benutzer/changepw/:token" component={ChangePW}/>
                                    <Route exact path={"/profil"} component={AsyncComponent(() => import("./user/profil"))}/>

                                    <Route exact path={"/personen"} component={PersonTeamView}/>
                                    <Route exact path={"/personen/:person_ID"} component={PersonTeamView}/>
                                    <Route exact path={"/abrechnung"} component={BillingAddressList}/>
                                    <Route exact path={"/registrieren"} component={UserEdit}/>
                                    <Route exact path={"/"} component={EventList}/>
                                    <Route exact path={"/veranstaltungen"} component={EventList}/>
                                    <Route exact path={"/aus-fortbildungen"} component={EventList}/>
                                    <Route exact path={"/prüfungen"} component={ExamList}/>
                                    <Route exact path={"/prüfungen/:exam_ID"} component={QuestionList}/>
                                    <Route exact path={"/prüfungsantritt/:exam_ID"} component={ExamstartStart}/>
                                    <Route exact path={"/prüfungsantritte"} component={ExamstartList}/>
                                    <Route exact path={"/prüfungsantritte/:exam_ID"} component={ExamstartList}/>
                                    <Route exact path={"/prüfungsantritt/:examstart_ID/resultat"} component={Examresult}/>
                                    <Route exact path={"/prüfungsantritt/:examstart_ID/resultatAlle"} component={ExamresultAll}/>
                                    <Route exact path={"/prüfungsantritt/:examstart_ID/zertifikat"} component={ExamCertificate}/>
                                    <Route exact path={"/prüfungsantritt/:examstart_ID/:examquestion_index"} component={Examstart}/>
                                    <Route exact path={"/veranstaltungen/absolventen"} component={AluminList}/>
                                    <Route exact path={"/veranstaltungen/:event_ID"} component={EventView}/>
                                    <Route exact path={"/anmeldungen"} component={RegistrationList}/>
                                    <Route exact path={"/anmeldungen/:registration_ID"} component={RegistrationView}/>
                                    <Route exact path={"/lizenz/beantragen"} component={LicenceFormWrapper}/>
                                    <Route exact path={"/lizenz/beantragen/:licenceKind/:sector"} component={LicenceFormWrapper}/>
                                    <Route exact path={"/lizenz/beantragen/:licenceKind"} component={LicenceFormWrapper}/>
                                    <Route exact path={"/lizenz/anträge"} component={LicenceListPage}/>
                                    <Route exact path={"/lizenz/anträge/:status"} component={LicenceListPage}/>
                                    <Route exact path={"/lizenz/personen"} component={LicenceListPersonen}/>
                                    <Route exact path={"/lizenz/abfragen"} component={LicencePublicQuery}/>
                                    <Route exact path={"/lizenz/suche"} component={LicenceQuery}/>
                                    <Route exact path={"/lizenz/imVerein"} component={LicencePersons}/>
                                    <Route exact path={"/lizenz/:licence_ID"} component={LicencePageWrapper}/>
                                    <Route exact path={"/lizenz"} component={LicenceProfile}/>
                                    <Route exact path={"/getCustomClub"} component={AsyncComponent(() => import("./core/custom_exporter"))}/>
                                    <Route path="/benutzer/login" component={Login}/>
                                    <Route path="/benutzer/logout" component={Login}/>

                                    <Route path={"/"} component={Notfound}/>
                                </Switch>

                                <Container name={"footer"} hideHeading>
                                    <div id={"divider"}/>


                                    <div id={"text"}>

                                        {Club.fullName},&nbsp;
                                        {new Date().getFullYear()},&nbsp;
                                        <a target={"_blank"} href={"http://www.turnsport-austria.at"}>turnsport-austria.at</a>, <a target={"_blank"} rel={"noopener noreferrer"} href={"https://www.turnsport.at/de/impressum"}>Impressum</a>
                                    </div>
                                    <div id={"images"}>
                                        powered by <a href={"//vereinfacht.at"} target={"_blank"} rel={"noopener noreferrer"}>VEREINfacht.at</a>
                                    </div>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <br/>
                                </Container>

                            </ErrorBoundary>
                        </MainContainer>

                    </div>
                </UserContext.Provider>
            </Router>
        );
    }
}


class MainContainer extends React.Component {
    state = {
        mounted: false,
    };
    componentDidMount = () => {
        window.setTimeout(() => {
            this.setState({mounted: true})
        }, 2000);
    };
    static contextType = UserContext;

    render() {
        const userContext = this.context;
        return <article style={{zIndex: 10}} id={"mainContainer"}
                        className={
                            (userContext.navOpen ?
                                    "open" :
                                    "closed"
                            ) +
                            (this.state.mounted ? " postLoad" : "")}
                        onClick={() => userContext.navOpen && !!userContext.user && userContext.user.status === "logged_in" && window.innerWidth < 500 && userContext.navToggle()}
        >
            {this.props.children}
        </article>
    }
}

function Notfound(props) {
    if (props.location.pathname.indexOf("/benutzer") > -1) {
        return null
    }
    return <Container name={"Fehler"}>Diese Funktion wird in kürze hinzugefügt!</Container>
}

export default App;

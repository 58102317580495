import {Container} from "../core/components";
import React, {useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import {MiniBtn, MyModal, MyReactTable} from "../core/input_fields";
import {QuestionEdit} from "./questionEdit";

export function QuestionList({match}) {
    const exam_ID = match?.params?.exam_ID;
    const [exam, setExam] = useState()
    const [questions, setQuestions] = useState()
    const [{error, loading}, setStatusVar] = useState({})
    const loadQuestion = () => {
        maxiGet(`/exam/${exam_ID}`).then(setExam)
        maxiGet(`/exam/${exam_ID}/questions`, {setStatusVar}).then(setQuestions)
    }
    useMemo(loadQuestion, []);

    return <Container name={"Fragen"} addName={` zu ${exam?.name}`}>
        <Status text={error} type={"error"}/>
        <MyModal trigger={<MiniBtn style={{marginBottom: 10}}>Frage hinzufügen</MiniBtn>}>
            {close => <QuestionEdit question={{exam_ID}} exam={exam} close={() => {
                close()
                loadQuestion()
            }}/>}
        </MyModal>

        <MyReactTable
            data={questions}
            defaultSorted={
                [
                    {id: "topic"},
                    {id: "type"},
                    {id: "question"},
                ]
            }
            columns={[
                {
                    Header: "Frage",
                    accessor: "question",
                    filterable: true,
                    Cell: ({original, value}) => <MyModal trigger={<em>{value || "keine Angabe"}</em>}>
                        {close => <QuestionEdit question={original} exam={exam} close={() => {
                            close()
                            loadQuestion()
                        }}/>}
                    </MyModal>

                },
                {
                    Header: "Thema",
                    id: "topic",
                    accessor: row => row.topic?.label,
                    filterable: true,
                    maxWidth: 120,
                },
                {
                    Header: "richtig",
                    accessor: "correct.percentage",
                    filterable: true,
                    maxWidth: 150,
                    Cell: ({value,original})=>`${value} % (${original.correct.count})`
                },
                {
                    Header: "Typ",
                    id: "type",
                    show: exam_ID==1,
                    accessor: row => row.type?.label,
                    filterable: true,
                    maxWidth: 120,
                },

            ]}
        />


    </Container>
}
import React, {useContext, useMemo, useRef, useState} from "react";
import Player from "@vimeo/player";
import {FaPause, FaPlay} from "react-icons/fa";
import {FormContext} from "../core/form_context";
import {TextfieldInput} from "../core/input_fields";
import Status from "../core/status";
import {MaxBtn} from "../core/components";

export default function VideoanleitungR({content, onEnded, data, editMode, ...rest}) {
    const [{error}, setStatusVar] = useState({});
    const [showIFrame, setShowIFrame] = useState(false);
    const [player, setPlayer] = useState(null);
    const [playerPaused, setPlayerPaused] = useState(true);
    const videoContainer = useRef();
    const imageContainer = useRef();
    const videoplayer = useRef();
    const videoWidth = data?.width || 480;
    const videoHeight = data?.width ? data.width / 16 * 9 : 270;

    const [autoplayedStopped,setAutoplaystopped]=useState(false)

    useMemo(() => {
        window.setTimeout(() => {
            console.log(videoplayer.current)
            if (!videoplayer.current) {
                console.log("not container")
                return
            }
            const player2 = new Player(videoplayer.current, {})
            //player2.setMuted(1)
            player2.on('play', function () {
                console.log('played the video!');
                //!autoplayedStopped && player2.pause()
                //setAutoplaystopped(true)
            });
            player2.on('ended', function () {
                console.log('ended the video!');
                onEnded && onEnded()
            });

            player2.getVideoTitle().then(function (title) {
                console.log('title:', title);
            });
            player2.on('loaded', ()=>{
                console.log("loaded")
                player2.pause()
            });

        }, 1000)

        /*if (showIFrame) {
            //console.log(videoContainer);
            imageContainer.current.style.display = "none";
            videoContainer.current.style.width = videoWidth + "px";
            videoContainer.current.style.height = videoHeight + "px";
            window.setTimeout(() => {
                if (videoContainer.current) {
                    videoContainer.current.style.width = "auto";
                    videoContainer.current.style.height = "auto";
                }
            }, 300);
            const playerLocal = new Player(videoContainer.current, {
                url: content,
                width: videoWidth,
                height: videoHeight,
                autoplay: true,
                controls: false,//Consts.f(true,false),
            });
            playerLocal.on("ended", onEnded)

            setPlayerPaused(false);
            setPlayer(playerLocal);
        }*/
    }, [videoplayer.current]);
    const context = useContext(FormContext);
    if (editMode) {
        return <>
            <TextfieldInput name={"Video URL"} tag={"block_content"} style={{width: "400px"}}/>
            <TextfieldInput name={"Größe"} tag={"data_width"} onChange={(e) => {
                context.setState({block: {...context.state.block, data: {...data, width: e.target.value}}});
            }
            } style={{width: "400px"}}/><br/>
        </>
    } else {
        if (content.indexOf("vimeo") === -1 || (data || {}).thumbnailID === undefined) {
            return <div style={{position: "relative", overflow: "hidden", width: "100%", paddingTop: "56.25%"}}>
                <iframe src={content + "&autoplay=1&muted=1&transcript=0&cc=0&pip=0&volume=0&airplay=0&color=ff0000&chromecast=0&title=0&unmute_button=0"} ref={videoplayer} style={{width: "100%", height: "100%", top: 0, left: 0, bottom: 0, right: 0, position: "absolute"}} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
            </div>
        }
        const thumbUrl = `https://i.vimeocdn.com/video/${(data || {}).thumbnailID}.jpg?mw=${videoWidth}&mh=${videoHeight}&q=70`;
        return <>
            <Status type={"error"} text={error}/>

            <div ref={videoContainer} style={{opacity: rest.showing ? 1 : 0.5, pointerEvents: rest.showing ? "auto" : "none"}}/>
            <div>{showIFrame ?
                <>
                    {/*<!--<iframe src={content} width="480" height="270" frameBorder="0" allow="autoplay; fullscreen"
                        allowFullScreen/>-->
                        */}

                </> :
                /*<img ref={imageContainer} onClick={() => setShowIFrame(true)} className={"videoContainer"} width="100%" height="100%" style={{cursor: "pointer"}} src={"https://i.vimeocdn.com/video/" + ((data || {}).thumbnailID || 869648478) + ".jpg?mw=480&mh=270&q=70"}/>*/
                <img ref={imageContainer} onClick={() => setShowIFrame(true)} className={"videoContainer"} width={videoWidth} height={videoHeight} style={{cursor: "pointer"}} src={thumbUrl}/>
            }
            </div>
            <MaxBtn onClick={() => {
                if (player) {
                    player.getPaused().then(paused => {
                        if (paused) {
                            player.play()
                            setPlayerPaused(!paused)
                        } else {
                            player.pause()
                            setPlayerPaused(!paused)
                        }
                    })
                } else {
                    setShowIFrame(true)
                    setPlayerPaused(false)
                }
            }}>{playerPaused ? <FaPlay/> : <FaPause/>}</MaxBtn>

            <ShowPlayProgress player={player}/>
        </>
    }
}
Number.prototype.pad = function (size) {
    var s = String(this);
    while (s.length < (size || 2)) {
        s = "0" + s;
    }
    return s;
}

function ShowPlayProgress({player}) {
    const [progress, setProgress] = useState(null);
    const [duration, setDuration] = useState(null);
    useMemo(() => {
        if (player) {
            window.setInterval(async () => {
                setProgress(await player.getCurrentTime())
                setDuration(await player.getDuration())
            }, 1000)
        }
    }, [player])
    return <>
        <DurationFormatter>{progress}</DurationFormatter> / <DurationFormatter>{duration}</DurationFormatter>
    </>
}

function DurationFormatter({children}) {
    const duration = children
    return <>
        {Math.floor((duration % 86400) / 3600).pad(2) + ":"}
        {Math.floor((duration % 3600) / 60).pad(2) + ":"}
        {Math.floor(duration % 60).pad(2) + ""}
    </>
}


import React, {useContext, useMemo, useState} from "react";
import {Container, MaxLink} from "../core/components";
import {maxiGet} from "../core/maxios";
import {encodeGetParams} from "../core/helpers";
import {LicencePrinter} from "./licenceQueryHelpers";
import {UserContext} from "../user/UserContext";
import Status from "../core/status";
import {QualificationsList} from "./licenceQualifications";
import Consts from "../core/consts";

export default function LicenceProfile({person_ID, simple}) {
    const [{error, loading}, setStatusVar] = useState({});
    const [{licences, person}, setLicence] = useState({});

    const context = useContext(UserContext);

    const queryLicence = e => context.user?.ID && maxiGet("/licence/query?" + encodeGetParams({person_ID: person_ID || context.user.ID}), {setStatusVar}).then(setLicence)
    useMemo(queryLicence, [context.user?.ID])
    return <Container name={"Meine Turnsport-Austria-Lizenzen"}>
        <Status type={"error"} text={error}/>
        {
            !simple && <>
                <MaxLink to={"/lizenz/beantragen"}>Turnsport-Austria-Lizenz beantragen</MaxLink>

                {
                    context?.user?.role > 80 && <>&nbsp;<MaxLink to={"/lizenz/anträge"}>Anträge anzeigen</MaxLink></>
                }
                {
                    (context?.user?.licenceQueryAllowed !== null || context?.user?.role > 20) && <>&nbsp;
                        <MaxLink to={"/lizenz/suche"}>Turnsport-Austria-Lizenz mit Name abfragen</MaxLink>
                        &nbsp;<MaxLink to={"/veranstaltungen/absolventen"}>Absolventen</MaxLink>
                    </>
                }
                &nbsp;<MaxLink to={"/lizenz/abfragen"}>Turnsport-Austria-Lizenz mit Lizenz-Nummer abfragen</MaxLink>
                &nbsp;<MaxLink to={"/lizenz/imVerein"}>Lizenzen meiner Personen</MaxLink>


                <h1>Prüfungen</h1>
                &nbsp;<MaxLink to={"/prüfungen"}>Prüfungen anzeigen</MaxLink>

                <h1>Buchbare Aus-/Fortbildungen</h1>
                &nbsp;<MaxLink to={"/aus-fortbildungen"}>Buchbare Aus-/Fortbildungen anzeigen</MaxLink>
            </>
        }
        <LicencePrinter {...{person, licences, simple, user: context.user}} />

        <QualificationsList/>


    </Container>
}


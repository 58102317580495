import React, {useContext, useState} from "react";

import {Container, InfoTooltip, MaxBtn} from "../core/components";
import {LightContainer, MyModal, MyReactTable, TextfieldInput} from "../core/input_fields";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import PersonEdit from "./personEdit";
import {dateFormatDateInline} from "../core/dateFuncs";
import {Redirect} from "react-router";
import {FormContextWrapper} from "../core/form_context";
import {UserContext} from "./UserContext";
import {PersonEditWrapper} from "./personHelpers";


export default function PersonList({match, persons, setPersons, error, loadPersons, nameCell, searchOnly, isClubAdmin, ...rest}) {
    const person_ID = parseInt(match?.params?.person_ID) || null;
    const context = useContext(UserContext);
    const [timer, setTimer] = useState(null)
    const [{loading, error: errorLocal, success}, setStatusVar] = useState({loading: false});
    const [state, setState] = useState({})
    const [redirToNoId, setRedirToNoId] = useState(false);

    const [onlyRegistrants, setOnlyRegistrants] = useState(false);

    if (person_ID && redirToNoId) {
        return <Redirect to={"/personen"}/>
    }
    const adminMode = context.user?.role >= 38;
    const searchPersons = (name, _, value) => {
        if (name === "name" && !!value) {
            timer && clearTimeout(timer)
            const t = window.setTimeout(() => {
                maxiGet("/person/search_by_name?name=" + value, {setStatusVar, fields: ["fullname", "dateOfBirth", "ID", "clubs", "sectors", "parent_ID","licenceNumber"]}).then(data => {
                    setPersons(data.persons)
                })
            }, 200)
            setTimer(t)
        }
    }


    return <LightContainer name={"Personen"}>
        <div style={{float: "right", marginTop: "-60px"}}>
            {
                isClubAdmin && <><MaxBtn onClick={() => {
                    setOnlyRegistrants(!onlyRegistrants)
                    loadPersons(!onlyRegistrants)
                }}>{onlyRegistrants ? "Alle Personen anzeigen" : "Nur Meldeverantwortliche anzeigen"}</MaxBtn>&nbsp;&nbsp;</>
            }
            {
                !searchOnly && <MyModal trigger={<MaxBtn>Neue Person erstellen</MaxBtn>}>
                    {close => <PersonEdit close={close} person={{}} reload={() => loadPersons(onlyRegistrants)}/>}
                </MyModal>
            }
        </div>
        <Status type={"error"} text={error || errorLocal}/>
        {
            (adminMode || searchOnly) && <FormContextWrapper value={{state, setState}} onSubmit={e => e.preventDefault()} afterUpdate={searchPersons}>
                <Status type={"error"} text={error}/>
                <TextfieldInput name={"Name"} tag={"name"} ph={"Mustermann, Max"} style={{minWidth: "160px"}}/>
            </FormContextWrapper>
        }


        <MyReactTable
            data={persons}
            loading={rest.loading || loading}
            columns={[
                {
                    Header: "Name",
                    accessor: "fullname",
                    Cell: nameCell || (({original, value}) => value),
                    filterable: !adminMode,
                },
                {
                    Header: <>V <InfoTooltip>
                        <b>V</b> falls die Person eigenständig ist, also andere Personen verwaltet. <br/>
                        <b>S</b> falls die Person nicht eigenständig ist, also von einer anderen Personen verwaltet wird, also eine Untergeordnete Person ist. <br/>
                    </InfoTooltip></>,
                    sortable: false,
                    filterable: true,
                    show: persons.some(a => a.parent_ID !== undefined),
                    id: "admin",
                    accessor: row => row.parent_ID === null ? "V" : "U",
                    maxWidth: 46,
                },
                {
                    Header: "Sparten",
                    id: "sectors",
                    filterable: true,
                    accessor: data => (data.sectors || []).map(a => a.label).join(", "),
                },
                {
                    Header: "Vereine",
                    id: "clubs",
                    accessor: row => row.clubs?.length ? row.clubs?.map(a => a.label).join(", ") : row.clubOld,
                    //Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                    filterable: true,
                },
                {
                    Header: "Geburtsdatum",
                    accessor: "dateOfBirth",
                    Cell: ({value}) => value ? dateFormatDateInline(new Date(value)) : "",
                    filterable: !adminMode,
                },
                {
                    Header: "TA-ID",
                    accessor: "licenceNumber",
                    maxWidth: 100,
                },
                {
                    maxWidth: 200,
                    show: !searchOnly,
                    Cell: ({original, value}) => <>
                        {/*<MyModal onClose={() => setRedirToNoId(true)} defaultOpen={original.ID === person_ID} trigger={<em>bearbeiten</em>}>
                        {close => <PersonEdit close={close} person={{original}} reload={loadPersons}/>}
                    </MyModal>*/
                        }
                        <PersonEditWrapper personLight={original} isClubAdmin={isClubAdmin} reload={() => {
                            loadPersons(onlyRegistrants)
                            searchPersons("name", false, state.name)
                        }}>
                            <em>bearbeiten</em>
                        </PersonEditWrapper>
                        <em onClick={() => {
                            if (window.confirm("Person überall löschen?")) {
                                maxiPost("/person/hide", {ID: original.ID}, {setStatusVar}).then(() => {
                                    loadPersons(onlyRegistrants)
                                    searchPersons("name", null, state.name)
                                })
                            }
                        }
                        }>löschen</em>
                    </>
                }
            ]}
        />
    </LightContainer>
}

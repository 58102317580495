import React, {useMemo, useState} from "react";

import {Loader, MaxBtn} from "../core/components";
import {CheckboxInput, DateTimeInput, EnumInput, InputContainer, LightContainer, MiniBtn, MyModal, SelectfieldInput, TagsInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import "./style.sass"
import UserHistory from "../user/userHistory";
import {BookingCodes, EventKindMap, LicenceKind, LicenceKindRequired, OfferType, OfferTypeMap} from "../core/enums";
import {FaEdit} from "react-icons/all";

const fields = {
    type: "Art",
    description: "Bezeichnung",
    price: "Preis",
    discountedPrice: "Preis reduziert",
    onlyOfficials: "Nur Offizielle?",
    bookingCode: "Buchungscode",
    requiredLicence: "Erforderliche Turnsport-Austria-Lizenz",
    lessons: "Unterrichtseinheiten",
    exam: "Prüfung",
    examPresenter: "Prüfer",
    //bookingEnd: "Buchbar bis",
};


export default function OfferEdit({offer,event, reload, close}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({offer: {...offer, exam: offer.exam_ID}})
    const [exams, setExams] = useState([])
    const [envPermissions, setEnvPermissions] = useState([])
    const handleSubmit = () => {
        !loading && maxiPost("/event/offer", state.offer, {setStatusVar})
            .then(() => {
                reload()
                close()
            })
    }

    useMemo(() => {
        maxiGet("/exam").then(setExams)
        maxiGet("/user/env_permissions?unique=1").then(setEnvPermissions)
    }, []);

    if (!event){
        return null
    }
    return <LightContainer name={"Option bearbeiten"}>
        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <Status type={"error"} text={error}/>
            {Object.entries(fields).map(([f, fieldName], i) => {
                switch (f) {
                    case "onlyOfficials":
                        return <>
                            <SelectfieldInput name={fieldName} tag={"offer_" + f} selectives={[[0, "beide"], [1, "nur Offizielle"], [2, "nur nicht Offizielle"]]}/>
                            <br/>
                        </>;
                    case "type":
                        return <>
                            <EnumInput name={fieldName} tag={"offer_" + f} type="reactselect" selectives={OfferType.filter(o => !!offer.parent_ID || o.value !== OfferTypeMap.selection)}/>
                            <br/>
                        </>;
                    case "exam":
                        if (event.kind?.value!==EventKindMap.pruefung){
                            return null;
                        }
                        return <InputContainer>
                            <SelectfieldInput name={fieldName} tag={"offer_" + f} type="reactselect" selectives={[{value: null, label: "keine"}, ...exams.map(exam => ({value: exam.ID, label: exam.name}))]}/>
                            <br/>
                        </InputContainer>;
                    case "examPresenter":
                        if (event.kind?.value!==EventKindMap.pruefung || !state.offer.exam){
                            return null;
                        }
                        return <>
                            <br/>
                            <SelectfieldInput name={fieldName}  tag={"offer_" + f} type="reactselect" isClearable width={410} selectives={envPermissions.map(({person})=>({label: person.fullname, value:person.ID}))}/>
                            <br/>
                        </>;
                    case "bookingCode":
                        if (!event.eventNoExt){
                            return null;
                        }
                        return <>
                            <br/>
                            <EnumInput name={fieldName} tag={"offer_" + f} type="reactselect" selectives={BookingCodes}/>
                            <br/>
                        </>;
                    case "lessons":
                        if (![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr, EventKindMap.pruefung].includes(event.kind?.value)) {
                            return null;
                        }
                        return <div>
                            <TextfieldInput name={fieldName} tag={"offer_" + f} ph={8} style={{width: "40px", marginRight: 0}}/>&nbsp;
                            UE zu je <SelectfieldInput tag={"offer_minutesPerLesson"} type={"reactselect"} inline width={"120px"} noLabel selectives={[45, 50, 60].map(a => ({value: a, label: a + " min"}))}/>
                            <br/>
                        </div>;
                    case "requiredLicence":
                        return <>
                            <br/>
                            <EnumInput name={fieldName}  tag={"offer_" + f} type="reactselect" isClearable width={410} selectives={LicenceKindRequired}/>
                            <br/>
                        </>;

                    default:
                        if (f.endsWith("Start") || f.endsWith("End")) {
                            return <InputContainer>
                                <DateTimeInput name={fieldName} midNight tag={"offer_" + f}/>
                                {i % 2 === 0 && <br/>}
                            </InputContainer>;
                        }
                        return <>
                            <TextfieldInput class={"form-control "} style={{width: "500px"}} name={fieldName} tag={"offer_" + f}/>
                            <br/>
                        </>;
                }

            })}
            <br/>
            <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
        </FormContextWrapper>
        <MaxBtn onClick={() => {
            if (window.confirm("wirklich löschen?")) {
                maxiPost("/event/offer/delete", {ID: offer.ID}, {setStatusVar}).then(reload)
            }
        }}>
            löschen
        </MaxBtn>
&nbsp;
        {
            offer?.type?.value === OfferTypeMap.mandatory && offer?.ID &&
            <MyModal trigger={<MaxBtn>Unteroption hinzufügen</MaxBtn>}>
                {close => <OfferEdit event={event} offer={{
                    event_ID: event.ID, event, parent_ID: offer.ID, price: 0, type: OfferType.find(o => o.value === OfferTypeMap.selection), discountedPrice: 0, onlyOfficials: 0, description: ""
                }} reload={reload} close={close}/>}
            </MyModal>
        }

        <br/>
        {
            offer.ID && <LightContainer name={"Veränderungen"}>
                <UserHistory userID={offer.ID} personType={"offer"}/>
            </LightContainer>
        }
    </LightContainer>


}

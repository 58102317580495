import React, {useMemo, useState} from "react";
import {Container} from "../core/components";
import {LightContainer, MyModal, MyReactTable} from "../core/input_fields";
import {maxiPost} from "../core/maxios";
import Status from "../core/status";
import LicenceProfile from "./licenceProfile";
import {PersonEditWrapper} from "../user/personHelpers";
import {FaCheck, FaUsers} from "react-icons/fa";
import {CombineUsers} from "../event/eventRegistrations";

export function LicencePersons({event, person_IDs, light, noUnite, reload, additionalColumns}) {

    const [state, setState] = useState({})
    const [{loading, error}, setStatusVar] = useState({});
    const [licences, setLicences] = useState([])
    const loadLicences = (status = state.filter?.status?.value) => maxiPost("/licence/my_persons", {event_ID: event?.ID, person_IDs}, {setStatusVar}).then(setLicences)
    useMemo(loadLicences, [])

    const [persons, setPersons] = useState({});
    const load = () => event && maxiPost(`/person/similar_to`, {"person_IDs": person_IDs}, {setStatusVar}).then(setPersons)
    useMemo(load, licences.map(p => p.person.ID))


    const Cont = light ? LightContainer : Container;

    return <Cont name={"Lizenzen"}>
        <Status type={"error"} text={error}/>
        <MyReactTable data={licences} columns={[
            {
                Header: "Person",
                accessor: "person.fullname",
                filterable: true,
                Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.person, reload: loadLicences}}><em>{value}</em></PersonEditWrapper>
            },
            {
                Header: "Geburtsdatum",
                accessor: "person.dateOfBirth",
                filterable: true,
            },
            {
                Header: "TA-ID",
                accessor: "person.licenceNumber",
                filterable: true,
                maxWidth: 100,
            },
            {
                Header: "Vereinen",
                id: "zusammenführen",
                filterable: true,
                show: event,
                maxWidth: 100,
                accessor: original => {
                    const value = (persons[original.person.ID] || []);
                    const taids = Array.from(new Set(value.map(a => a.licenceNumber).filter(b => !!b)));
                    const allHavingSameLicenceNumber = taids.length === 1 && !value.some(a => a.licenceNumber !== taids[0])
                    return allHavingSameLicenceNumber || value.length === 1 ? "ja" : "nein"
                },
                Cell: ({original}) => {
                    const value = persons[original.person.ID] || [];

                    const taids = Array.from(new Set(value.map(a => a.licenceNumber).filter(b => !!b)));
                    const allHavingSameLicenceNumber = taids.length === 1 && !value.some(a => a.licenceNumber !== taids[0])

                    return <>
                        {original.person.parent_ID ? "U" : "V"}&nbsp;
                        {
                            value.length > 1 && <MyModal trigger={<em><FaUsers/> {value?.length || 0} {allHavingSameLicenceNumber ? <FaCheck style={{color: "green"}}/> : " / " + taids.length}</em>}>
                                {close => <CombineUsers {...{
                                    ...original, close, reload: () => {
                                        load()
                                        reload && reload()
                                    }, persons: value
                                }}/>}
                            </MyModal>
                        }
                    </>
                },
            },
            {
                Header: "Lizenzen",
                id: "licences",
                accessor: row => row.licences.map(l => l.kind.label.replace("Turnsport Austria: Trainer*innen", "Trainer").replace("-Lizenz", "") + ": " + l.sector.value.toUpperCase()).join(", "),
                filterable: true,
                Cell: ({original, value}) => <MyModal trigger={<em>{value}</em>}>
                    <LicenceProfile person_ID={original.licences[0]?.person_ID} simple/></MyModal>,
            },
            {
                Header: "Turn10 Theorie",
                show: event,
                id: "turn10_theorie",
                maxWidth: 120,
                accessor: row => row.examstarts.find(l => [1].includes(l.exam_ID))?.passed ? "bestanden" : "",
                filterable: true,
            },
            {
                Header: "Turn10 Basis",
                id: "licences_turn10b",
                maxWidth: 120,
                accessor: row => row.examstarts.find(l => [2, 3].includes(l.exam_ID))?.resultingLicence.replace(/Turn10® 2025\+ Wertungsrichter\*in (.+)stufe \((.+)\)/, "$2"),
                filterable: true,
                Cell: ({value}) => <MyModal trigger={<em>{value}</em>}>{value}</MyModal>
            },
            {
                Header: "Turn10 Oberstufe",
                id: "licences_turn10o",
                maxWidth: 120,
                accessor: row => row.examstarts.find(l => [4, 5].includes(l.exam_ID))?.resultingLicence.replace(/Turn10® 2025\+ Wertungsrichter\*in (.+)stufe \((.+)\)/, "$2"),
                filterable: true,
                Cell: ({value}) => <MyModal trigger={<em>{value}</em>}>{value}</MyModal>
            },
            ...(additionalColumns || [])
        ]}/>

    </Cont>
}



import {Container} from "../core/components";
import React, {useMemo, useState} from "react";
import {maxiGet} from "../core/maxios";
import Status from "../core/status";
import {MyReactTable} from "../core/input_fields";
import {Link} from "react-router-dom";
import {dateFormatTime} from "../core/dateFuncs";
import {PersonEditWrapper} from "../user/personHelpers";

export function ExamstartList({match}) {
    const exam_ID = match?.params?.exam_ID;
    const [examstarts, setExamstarts] = useState()
    const [{error, loading}, setStatusVar] = useState({})
    const loadExamstarts = () => maxiGet(`/exam/${exam_ID ? exam_ID + "/" : ""}examstarts`, {setStatusVar}).then(setExamstarts)
    useMemo(loadExamstarts, []);


    return <Container name={"Antritte"}>
        <Status text={error} type={"error"}/>

        <MyReactTable
            data={examstarts}
            defaultSorted={
                [
                    {id: "examstart.timeCreated", desc: true},

                ]
            }
            columns={[
                {
                    Header: "Zeitpunkt",
                    accessor: "examstart.timeCreated",
                    Cell: ({original, value}) => !exam_ID ? dateFormatTime(value) : <Link to={`/prüfungsantritt/${original.examstart.ID}/0`}>{dateFormatTime(value)}</Link>,
                },
                {
                    Header: "Person",
                    accessor: "person.fullname",
                    show: !!exam_ID,
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper personLight={original.person}><em>{value}</em></PersonEditWrapper>
                },
                {
                    Header: "Vereine",
                    accessor: "person.clubs",
                    filterable: true,
                },
                {
                    Header: "Bestanden",
                    id: "passed",
                    accessor: row => row.examstart.matrix ? "Prüfer" : (row.examstart?.passed ? "ja" : (row.examstart.timeLastModified === null ? "nicht angetreten" : "nein")),
                    filterable: true,
                    maxWidth: 120,
                },
                {
                    Header: "Punkteanzahl",
                    accessor: "examstart.score",
                    filterable: true,
                    maxWidth: 120,
                },
                {
                    Header: "Resultat",
                    accessor: "examstart.ID",
                    maxWidth: 120,
                    Cell: ({value, original}) => <Link to={`/prüfungsantritt/${value}/resultat${original.examstart.matrix ? "Alle" : ""}`}>anzeigen</Link>
                },

            ]}
        />


    </Container>
}
import React from "react";
import {apiGet, apiPost} from "../core/api";
import Status from "../core/status";
import {FormContextWrapper} from "../core/form_context";
import {Upload} from "tus-js-client"
import {LightContainer, TextfieldInput} from "../core/input_fields";
import {MaxBtn} from "../core/components";
import Consts from "../core/consts";

export default class MyVimeoUpload extends React.Component {
    constructor(props) {
        super(props);
        this.apiGet = apiGet.bind(this);
        this.apiPost = apiPost.bind(this);
        this.setState = this.setState.bind(this);
        this.fileField = React.createRef();

        this.state = {
            showing: 1,
            loading: false,
            progress: 0,
            video: {
                name: "",
                upload_link: null,
            },
        };
    }

    saveFile = (close) => (e) => {
        e.preventDefault();

        if (this.fileField.current.files.length !== 1) {
            this.setState({error: "Bitte wähle ein Video aus!"});
            return
        }
        this.setState({loading: true})

        this.apiPost("/vimeo/create_upload_link", {
            name: this.props.title,
            size: this.fileField.current.files[0].size,
            question_ID: this.props.question_ID,
        }, resp => {
            var formData = new FormData();
            formData.append("file_data", this.fileField.current.files[0]);
            const file = this.fileField.current.files[0];
            this.setState({loading: true, error: ""});
            var upload = new Upload(file, {
                uploadUrl: resp.upload_link,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                chunkSize: 20 * 1024 * 1024,
                metadata: {
                    filename: file.name,
                    filetype: file.type
                },
                onError: (error) => {
                    this.setState({
                        error: error.message,
                        loading: false,
                        status: ""
                    })
                    console.log("Failed because: " + error)
                },
                onProgress: (bytesUploaded, bytesTotal) => {
                    this.setState({
                        loading: true,
                        progress: Math.round(bytesUploaded / bytesTotal * 100)
                    })
                },
                onSuccess: () => {
                    this.setState({
                        success: "Vielen Dank! Die Video-Datei wurde erfolgreich hochgeladen und wird jetzt besichtet.",
                        loading: false,
                    })
                    this.props.setUrl && this.props.setUrl(resp.vimeo_url)
                }
            })

            // Start the upload
            upload.start()

            /*axios.put(resp.upload_link, this.fileField.current.files[0], {
                headers: {
                    //'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: progressEvent => {
                    this.setState({
                        loading: true,
                        progress: Math.round(progressEvent.loaded / progressEvent.totalSize * 100)
                    })
                }
            })
                .then(response => {
                    this.setState({
                        success: "Vielen Dank! Die Datei wurde erfolgreich hochgeladen und wird jetzt besichtet.",
                        loading: false,
                    })
                })
                .catch((error) => {
                    console.log("error", error);
                    //this.setState({loading: false})
                    this.setState({
                        error: error.message,
                        loading: false,
                        status: ""
                    })
                });*/
        })

    };
    handleChange = (e) => {

        const s = e.target.name.split("_");
        this.setState({
            [s[0]]: Object.assign(
                {},
                this.state[s[0]],
                {[s[1]]: e.target.type === 'checkbox' ? e.target.checked : e.target.value}
            )
        });

    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    render() {
        return <LightContainer name={""}>
            <Status type={"error"} text={this.state.error || ""}/>
            <Status type={"success"} text={this.state.success || ""}/>
            {
                !this.state.success &&
                <>
                    <form id={"profile_picture_form"} onChange={this.handleChange} action={this.state.video.upload_link}
                          method={"POST"} enctype="multipart/form-data">
                        <div id="excel_file">

                            <label>
                                    <span>
                                        {this.props.name || "Video"}
                                    </span>
                                <input
                                    style={{paddingTop: "20px"}}
                                    type={"file"}
                                    name={"file_data"}
                                    ref={this.fileField}
                                />
                                {
                                    this.state.loading &&
                                    <label>
                                        <img alt={"loader"} src="https://uid-suche.eu/src/img/ajax-loader-fff.gif"/> {this.state.progress && <>{this.state.progress} % {this.state.progress === 100 && <span>(verarbeite Video)</span>}</>}
                                    </label>
                                }
                            </label>

                        </div>
                    </form>
                    <MaxBtn
                        onClick={this.saveFile(() => {
                        })}
                    >Video uploaden</MaxBtn>
                    {
                     Consts.f(true,false) &&    <MaxBtn onClick={()=>this.props.setUrl("https://player.vimeo.com/video/1035239643?h=ee66411ea6")}>asdf</MaxBtn>
                    }
                        </>
            }
        </LightContainer>

    }
}

import {Container, MaxBtn} from "../core/components";
import React, {useContext, useMemo, useState} from "react";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {CheckboxInput, MyReactTable} from "../core/input_fields";
import {Link} from "react-router-dom";
import {PersonEditWrapper} from "../user/personHelpers";
import {downloadAsExcel} from "../core/download";
import {dateFormatTime} from "../core/dateFuncs";
import {QuestionTopicLabelMap} from "../core/enums";
import {FormContextWrapper} from "../core/form_context";
import {UserContext} from "../user/UserContext";

export function ExamresultAll({match}) {
    const {examstart_ID} = match.params;
    const {user} = useContext(UserContext)
    const [data, setData] = useState()
    const [state, setState] = useState({});
    const [{error, loading}, setStatusVar] = useState({})
    const loadExamstart = () => maxiGet(`/exam/examstart/${examstart_ID}/result_all`, {setStatusVar}).then(data => {
        setData(data)
    })
    useMemo(loadExamstart, [examstart_ID]);

    const {examstarts, exam, topics} = data || {};


    //const passedWhole = points_per_topic && !Object.entries(points_per_topic).some(([key, points]) => points < 3) && points >= 25


    return <Container name={"Prüfungsergebnis"} addName={<> zu {exam?.name}</>}>
        <Status text={error} type={"error"}/>
        <FormContextWrapper value={{state, setState}}>
            <CheckboxInput name={"Detailergebnisse"} tag={"details"}/>
        </FormContextWrapper>

        {
            examstarts && <>
                <MyReactTable
                    data={examstarts}
                    loading={loading}
                    columns={[
                        {
                            Header: "Person",
                            accessor: "person.fullname",
                            filterable: true,
                            Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.person, reload: loadExamstart}}><em>{value}</em></PersonEditWrapper>
                        },
                        {
                            Header: "Geburtsdatum",
                            maxWidth: 120,
                            accessor: "person.dateOfBirth",
                            filterable: true,
                        },
                        {
                            Header: "Vereine",
                            accessor: "person.clubs",
                            filterable: true,
                        },
                        {
                            Header: "Praxis best.",
                            id: "passedQuestions",
                            accessor: row => row.examstart.matrix ? "Prüfer" : (row.examstart?.passedQuestions ? "ja" : (row.examstart.maxLastModified ? "nein" : "nicht ang.")),
                            filterable: true,
                            maxWidth: 80,
                        },
                        {
                            Header: "Disqu.",
                            id: "passedOverride",
                            accessor: row => row.examstart.matrix ? "Prüfer" : (row.examstart?.passedOverride ? "nein" : "ja"),
                            maxWidth: 80,
                            Cell: ({original, value}) => <TogglePassedOverride examstart={original.examstart} value={value} reload={loadExamstart}/>
                        },
                        {
                            Header: "Best.",
                            id: "passed",
                            accessor: row => row.examstart.matrix ? "Prüfer" : (row.examstart?.passed ? "ja" : (row.examstart.maxLastModified ? "nein" : "nicht ang.")),
                            filterable: true,
                            maxWidth: 80,
                        },
                        {
                            Header: "Lizenz",
                            accessor: "examstart.resultingLicence",
                            filterable: true,
                            maxWidth: 80,
                        },
                        {
                            Header: "Resultat",
                            accessor: "examstart.ID",
                            maxWidth: 120,
                            Cell: ({value, original}) => <Link to={`/prüfungsantritt/${value}/resultat${original.examstart.matrix ? "Alle" : ""}`}>anzeigen</Link>
                        },

                        ...(topics && state.details ? topics.map(topic => ({
                            Header: QuestionTopicLabelMap[topic],
                            filterable: true,
                            accessor: "examstart.points." + topic + ".points",
                            Cell: ({original, value}) => <span style={{color: (original?.examstart?.points || {})[topic]?.save > 0 ? "orange" : (value < 5 ? "red" : "green")}}>{value}</span>,
                            maxWidth: 120,
                        })) : [])

                    ]}
                />
                <MaxBtn onClick={() => window.confirm("Wirklich?") && maxiPost(`/exam/examstart/${examstart_ID}/result_all_recalculate`, {}, {setStatusVar}).then(loadExamstart)}>
                    alle Resultate neu berechnen
                </MaxBtn>&nbsp;
                <MaxBtn onClick={() => window.confirm("Wirklich?") && maxiPost(`/exam/examstart/${examstart_ID}/send_all_certificates`, {}, {setStatusVar})}>
                    alle Bestätigungen aussenden
                </MaxBtn>&nbsp;
                <MaxBtn onClick={() => maxiGet(`/exam/examstart/${examstart_ID}/result_all_excel`, {setStatusVar})
                    .then(({data}) => downloadAsExcel(data, `Absolventen_${exam?.name}_${dateFormatTime(new Date())}.xlsx`))}>
                    als Excel herunterladen
                </MaxBtn>&nbsp;
                {
                    user?.role >= 38 && <MaxBtn onClick={() => window.confirm("Erlaubt die Eingabe bei dieser Prüfung wieder, nachdem diese durch den Prüfer beendet wurde, indem er auf diese Seite gegangen ist.") &&
                        maxiPost(`/exam/examstart/${examstart_ID}/restart`, {},{setStatusVar})}>
                        Prüfung erneut öffnen
                    </MaxBtn>
                }


            </>
        }


    </Container>
}


function TogglePassedOverride({examstart, value, reload}) {
    const [{loading, error}, setStatusVar] = useState({})

    if (!examstart.passedQuestions) {
        return null;
    }
    return <>
        <Status type={"error"} text={error}/>
        <em onClick={() => maxiPost(`/exam/examstart/${examstart.ID}/toggle_override`, {}, {setStatusVar}).then(reload)}>
            {value}
        </em>

    </>
}
import React, {useContext, useMemo, useState} from "react";

import {Container, Loader, MaxBtn} from "../core/components";
import {CheckboxInput, DateTimeInput, EditorInput, EnumInput, InputContainer, LightContainer, MiniBtn, MyModal, MyReactTable, SelectfieldInput, TagsInput, TextareaInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import "./style.sass"
import UserHistory from "../user/userHistory";
import {EventKind, EventKindMap, EventStatus, SectorType, SectorTypeLicence, LicenceKind, SectorTypeMap} from "../core/enums";
import Consts from "../core/consts";
import {FaEdit} from "react-icons/all";
import {UserContext} from "../user/UserContext";
import PersonList from "../user/personList";
import {PersonEditWrapper} from "../user/personHelpers";
import {dateFormatTime} from "../core/dateFuncs";

const fields = {
    name: "Name",
    //status: "Status",
    eventStart: "Veranstaltungs-Start",
    eventEnd: "Veranstaltungs-Ende",
    bookingStart: "Buchbar ab",
    bookingEnd: "Buchbar bis",
    showStart: "Anzeigen ab",
    prebookingEnd: "Frühbuchungs-Ende",
    allowOfficials: "Offizielle erlauben",
    teamsRequired: "Anmeldung als Mannschaft",
    maxParts: "maximale Teilnehmeranzahl",
    city: "Ort",
    eventNoExt: "Veranstaltungs-Nr.",
    minPermission: "Berechtigung",
    emailAddressesRequired: "E-Mail Adressen erforderlich",
    kind: "Veranstaltungsart",
    sector: "von Sparte",
    forSectors: "für Sparten",
    forLicence: "Für Lizenz",
    //lessons: "Unterrichtseinheiten",
    "titleForCertificate": "Bezeichnung auf Bestätigung",
    focus: "Schwerpunkte",
    "descriptionForCertificate": "Text auf Bestätigung",
    "examRequirement": "Prüfung vorausgesetzt",
    //"examIncluded": "Prüfung",
    environment: "Umgebung",
    description: "Beschreibung",
};


export default function EventEdit({event, reload}) {
    const {user} = useContext(UserContext)
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({event})
    const [exams, setExams] = useState([])
    const handleSubmit = () => {
        if (loading) {
            return
        }
        setStatusVar({loading: true})
        maxiPost("/event", state.event, {setStatusVar})
            .then((resp) => {
                if (!event.ID) {
                    setState(old => ({event: {...old.event, ID: resp.event_ID}}))
                } else {
                    reload()
                }
            })
    }
    useMemo(() => maxiGet("/exam").then(setExams), []);

    if (event.ID !== state.event.ID && !!state.event.ID) {
        return window.location.href = `/veranstaltungen/${state.event.ID}`
    }
    return <LightContainer name={event.ID && "Veranstaltung bearbeiten"}>
        <FormContextWrapper value={{state, setState}} onSubmit={handleSubmit}>
            <Status type={"error"} text={error}/>
            {Object.entries(fields).map(([f, fieldName], i) => {
                switch (f) {
                    case "description":
                    case "information":
                        return <EditorInput name={fieldName} key={"event_" + f} tag={"event_" + f} style={{width: "100%"}}/>;
                    case "allowOfficials":
                    case "emailAddressesRequired":
                        return <InputContainer>
                            <CheckboxInput name={fieldName} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    case "teamsRequired":
                        return <InputContainer>
                            <SelectfieldInput name={fieldName} selectives={[
                                ["0", "Nur als Einzelpersonen"],
                                ["1", "Nur als Teams"],
                                ["2", "beides"],
                            ]} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    case "environment":
                        return <>
                            <TagsInput name={<>Umgebung&nbsp;
                                {
                                    user?.role >= 38 && <MyModal trigger={<MiniBtn  style={{marginBottom: 5}} ><FaEdit/></MiniBtn>}><EnvironmentPermissionsEdit/></MyModal>
                                }
                            </>} entity={"env"} ID={0} tag={"event_environment"}/>
                        </>;
                    case "sector":
                    case "forSectors":
                    case "kind":
                        if (f === "forSectors" && ![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr, EventKindMap.pruefung].includes(state.event.kind?.value)) {
                            return null;
                        }
                        return <InputContainer>
                            <EnumInput name={fieldName} tag={"event_" + f} type="reactselect" selectives={{sector: SectorType.filter(a => a.value !== SectorTypeMap.tfa), forSectors: SectorTypeLicence, kind: EventKind}[f]} multiple={{forSectors: true}[f] ?? false}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;

                    case "forLicence":
                        if (![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr, EventKindMap.pruefung].includes(state.event.kind?.value)) {
                            return null;
                        }
                        return <div>
                            <EnumInput name={fieldName} tag={"event_" + f} multiple width={380} type="reactselect" selectives={LicenceKind}/>
                            <br/>
                        </div>;
                    case "minPermission":
                        return <InputContainer>
                            <SelectfieldInput name={"Berechtigung"} tag={"event_" + f} selectives={Object.entries(Consts.permissionMap)}/>
                        </InputContainer>
                    case "examRequirement":
                    case "examIncluded":
                        return <InputContainer>
                            <SelectfieldInput name={fieldName} tag={"event_" + f} type="reactselect" selectives={[{value: null, label: "keine"}, ...exams.map(exam => ({value: exam.ID, label: exam.name}))]}/>
                        </InputContainer>;
                    case "status":
                        return <InputContainer>
                            <EnumInput name={fieldName} tag={"event_" + f} type="reactselect" selectives={EventStatus}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                    case "descriptionForCertificate":
                        if (![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr].includes(state.event.kind?.value)) {
                            return null;
                        }
                        return (state.event.descriptionForCertificate?.length || 0) < 30 ?
                            <InputContainer>
                                <TextfieldInput name={fieldName} tag={"event_" + f}/>
                                {i % 2 === 0 && <br/>}
                            </InputContainer> :
                            <div>
                                <TextareaInput name={fieldName} tag={"event_" + f}/>
                                <br/>
                            </div>


                    case "name":
                        return <>
                            <TextfieldInput class={"form-control "} width={500} name={fieldName} tag={"event_" + f}/>
                            <br/>
                        </>;
                    default:
                        if (["titleForCertificate","focus"].includes(f) && ![EventKindMap.fortbildung, EventKindMap.ausbildung, EventKindMap.ausbildungwr].includes(state.event.kind?.value)) {
                            return null;
                        }
                        if (f.endsWith("Start") || f.endsWith("End")) {
                            return <InputContainer>
                                <DateTimeInput name={fieldName}
                                               {...{[f.endsWith("Start") ? "maxDate" : "minDate"]: state.event[f.endsWith("Start") ? f.replace("Start", "End") : f.replace("End", "Start")]}}
                                               midNight tag={"event_" + f}/>
                                {i % 2 === 0 && <br/>}
                            </InputContainer>;
                        }
                        return <InputContainer>
                            <TextfieldInput class={"form-control "} name={fieldName} tag={"event_" + f}/>
                            {i % 2 === 0 && <br/>}
                        </InputContainer>;
                }

            })}
            <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
        </FormContextWrapper>
        {
            event.ID && <>
            <MaxBtn onClick={() => window.confirm("Wirklich löschen?") && maxiPost("/event/delete", {ID: event.ID}, {setStatusVar}).then(() => window.location.href = "/")}>Löschen</MaxBtn>
             &nbsp;
            <MaxBtn onClick={()=>window.confirm("Veranstaltung duplizieren?") && maxiPost(`/event/${event.ID}/duplicate`, {}, {setStatusVar}).then((event) => window.location.href = "/veranstaltungen/"+event.ID)}>Duplizieren</MaxBtn>
            </>
        }

        {
            event.ID && <LightContainer name={"Veränderungen"}>
                <UserHistory userID={event.ID} personType={"event"}/>
            </LightContainer>
        }
    </LightContainer>

}

function EnvironmentPermissionAddDialog({person_ID, event_ID, loadPermissions}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    //const add = () =>
    const [state, setState] = useState({})
    return <Container name={"Art der Berechtigung"}>
        <Status type={"error"} text={error}/>
        <FormContextWrapper value={{state, setState}} onSubmit={(e) => {
            e.stopPropagation()
            if (!state?.env?.env?.value) {
                setStatusVar({error: "Bitte Umgebung auswählen"})
                return
            }
            !loading && maxiPost(`/environment/${state.env.env.value}/permissions/add`, {person_ID}, {setStatusVar}).then(loadPermissions);
        }}>
            <TagsInput name={"Umgebung"} entity={"env"} ID={0} tag={"env_env"}/>
            <MaxBtn>Erteilen</MaxBtn> <Loader loading={loading}/>
        </FormContextWrapper>
    </Container>
}


function EnvironmentPermissionsEdit({}) {
    const [{loading, error}, setStatusVar] = useState({})
    const [permissions, setPermissions] = useState([])
    const [persons, setPersons] = useState([]);
    const permittedPersons = permissions.map(a => a.person.ID)
    const loadPermissions = () => maxiGet("/user/env_permissions", {setStatusVar}).then(setPermissions)
    useMemo(loadPermissions, [])
    return <Container name={"Berechtigungen bearbeiten"}>
        <Status type={"error"} text={error}/>
        <MyModal trigger={<MaxBtn>Person hinzufügen</MaxBtn>}>
            <LightContainer name={"Berechtigung erteilen"}>
                <PersonList
                    persons={persons}
                    error={""}
                    setPersons={setPersons}
                    showSearch
                    nameCell={({original, value}) => <>
                            <MyModal trigger={<em>{value} (hinzufügen)</em>}>
                                <EnvironmentPermissionAddDialog person_ID={original.ID} loadPermissions={loadPermissions}/>
                            </MyModal>
                        </>
                    }
                    loadPersons={loadPermissions}
                    match={{}}
                    loading={false}
                />
            </LightContainer>
        </MyModal>

        <MyReactTable
            data={permissions}
            columns={[
                {
                    Header: "Umgebung",
                    accessor: "env.name",
                    filterable: true,
                },
                {
                    Header: "Person",
                    accessor: "person.fullname",
                    filterable: true,
                    Cell: ({original, value}) => <PersonEditWrapper {...{personLight: original.person, reload: loadPermissions}}><em>{value}</em></PersonEditWrapper>,
                },
                {
                    Header: "Vergeben",
                    accessor: "permission.timeAdded",
                    filterable: true,
                    Cell: ({value}) => dateFormatTime(value),
                },
                {
                    Header: "Entzogen",
                    accessor: "permission.timeRevoked",
                    filterable: true,
                    Cell: ({original, value}) => value ? dateFormatTime(value) :
                        <MiniBtn onClick={()=>window.confirm("Entziehen?") && maxiPost(`/permissions/${original.permission.ID}/revoke`, {setStatusVar}).then(loadPermissions)}>entziehen</MiniBtn>,
                }
            ]}/>
    </Container>
}
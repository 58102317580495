import React, {useState} from "react";
import {maxiGet} from "../core/maxios";
import {MyModal} from "../core/input_fields";
import {Loader} from "../core/components";
import PersonEdit from "./personEdit";
import Status from "../core/status";

export function PersonEditWrapper({personLight, reload, children}) {
    const [person, setPerson] = useState(null);
    const [isClubAdmin, setIsClubAdmin] = useState(false);
    const [{error, loading, success}, setStatusVar] = useState({});
    const loadPerson = () => {
        setPerson(null)
        maxiGet("/person/" + personLight.ID, {setStatusVar}).then(resp => {
            setPerson(resp.person)
            setIsClubAdmin(resp.isClubAdmin)
        })
    }


    return <MyModal trigger={children} onOpen={loadPerson}>
        {close => <>
            <Loader loading={loading}/>
            {
                person && <PersonEdit {...{person, loadPerson, reload, close, isClubAdmin}}/>
            }
            <Status type={"error"} text={error}/>
        </>
        }
    </MyModal>
}

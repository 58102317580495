import {Container, Loader, MaxBtn} from "../core/components";
import React, {useMemo, useState} from "react";
import {maxiDelete, maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import {CheckboxInput, EnumInput, LightContainer, MiniBtn, MyModal, TextareaInput, TextfieldInput} from "../core/input_fields";
import {ExamTypeMap, QuestionTopic, QuestionTopicMap, QuestionType} from "../core/enums";
import {FormContextWrapper} from "../core/form_context";
import UserHistory from "../user/userHistory";
import {FaTrashAlt} from "react-icons/fa";
import MyVimeoUpload from "./vimeoUpload";
import VideoanleitungR from "./video";


export function QuestionEditWrapper({question_ID, exam, close}) {

    const [question, setQuestion] = useState(null)
    useMemo(() => {
        maxiGet("/exam/question/" + question_ID).then(setQuestion)
    }, [question_ID]);

    return question && <QuestionEdit {...{question, exam, close}}/>
    
}

export function QuestionEdit({question, exam, close}) {

    const [state, setState] = useState({question})

    const [choices, setChoices] = useState()
    const [scores, setScores] = useState()
    const [{error, loading}, setStatusVar] = useState({})
    const setVideo = (u) => setState(q => ({question: {...q.question, video: u}}));
    const setVideo2 = (u) => setState(q => ({question: {...q.question, video2: u}}));
    const loadChoices = () => state.question.ID && maxiGet(`/exam/question/${state.question.ID}/choices`, {setStatusVar}).then(({choices, scores}) => {
        setChoices(choices)
        setScores(scores)
    })
    useMemo(loadChoices, [question.ID]);

    return <Container name={"Frage Bearbeiten"}>
        <Status text={error} type={"error"}/>
        <FormContextWrapper value={{state, setState}} onSubmit={() => maxiPost(`/exam/question`, state.question, {setStatusVar}).then(state.question.ID ? close : (resp) => {
            setState({question: resp.question})
        })}>
            <EnumInput name={"Thema"} tag={"question_topic"} type="reactselect" selectives={QuestionTopic}/><br/>

            {
                exam?.type.value === ExamTypeMap.selfservice && <>
                    <EnumInput name={"Kategorie"} tag={"question_type"} type="reactselect" selectives={QuestionType}/>
                    <TextareaInput tag={"question_question"} name={"Frage"}/>
                    <TextfieldInput tag={"question_remark"} name={"Anmerkung"}/>
                </>
            }

            {
                exam?.type.value === ExamTypeMap.synchronous && <>
                    <TextfieldInput tag={"question_question"} name={"Titel"}/>
                    <TextfieldInput tag={"question_remark"} name={"Anmerkung"}/>
                    <br/>
                    {
                        state.question?.video ?
                            <>
                                <strong>Video:</strong> <MiniBtn onClick={() => setVideo(null)}>Neues hochladen?</MiniBtn>
                                <br/>
                                <br/>
                                <VideoanleitungR content={state.question.video}/>
                            </> :
                            <MyVimeoUpload title={state.question.question} question_ID={state.question.ID} setUrl={setVideo}/>
                    }
                    <br/>
                    <br/>
                    {
                        (state.question?.topic?.value === QuestionTopicMap.sprung || state.question?.topic?.value === QuestionTopicMap.tramp) && (
                            state.question?.video2 ?
                                <>
                                    <strong>Video 2:</strong> <MiniBtn onClick={() => setVideo(null)}>Neues hochladen?</MiniBtn>
                                    <br/>
                                    <br/>
                                    <VideoanleitungR content={state.question.video2}/>
                                </> :
                                <MyVimeoUpload name={"Video 2"} title={state.question.question} question_ID={state.question.ID} setUrl={setVideo2}/>)
                    }

                </>
            }


            <br/>
            <MaxBtn>{state.question.ID ? "Speichern" : "Hinzufügen"}</MaxBtn>&nbsp;
            <MaxBtn onClick={(e) => {
                e.preventDefault();
                window.confirm("Frage löschen") && maxiDelete(`/exam/question/${state.question.ID}`).then(close)
            }}>Löschen <FaTrashAlt/></MaxBtn>
        </FormContextWrapper>


        <LightContainer name={"Antwortmöglichkeiten"}>
            <ul style={{listStyleType: "none", paddingLeft: 0, lineHeight: "34px"}}>
                {
                    choices?.map(choice => <li>

                        {
                            choice.data ? <>
                                <b style={{display: "inline-block", width: 80}}>A-Note:</b> {choice.data.a1},
                                <b style={{display: "inline-block", width: 80, marginLeft: 10}}>B-Note:</b> {choice.data.b1},
                                <b style={{display: "inline-block", width: 80, marginLeft: 10}}>Neutral:</b> {choice.data.neutral},
                                {
                                    (state.question?.topic?.value === QuestionTopicMap.sprung || state.question?.topic?.value === QuestionTopicMap.tramp) && <>
                                        <br/>
                                        <b style={{display: "inline-block", width: 80}}>A-Note 2:</b> {choice.data.a2},
                                        <b style={{display: "inline-block", width: 80, marginLeft: 10}}>B-Note 2:</b> {choice.data.b2},
                                        <b style={{display: "inline-block", width: 80, marginLeft: 10}}>Neutral 2:</b> {choice.data.neutral2},
                                    </>
                                }
                            </> : <>
                                <input type={"checkbox"} checked={choice.correct}/>
                                {choice.text}
                            </>
                        }
                        &nbsp;&nbsp;
                        <MiniBtn onClick={() => window.confirm("Antwortmöglichkeit entfernen") && maxiDelete(`/exam/question/${question.ID}/choice/${choice.ID}`).then(loadChoices)}><FaTrashAlt/></MiniBtn>
                    </li>)
                }
            </ul>

            <MyModal trigger={<MaxBtn>Antwortmöglichkeit hinzufügen</MaxBtn>}>
                {
                    close => state.question.ID ? <AddChoice question={state.question} exam={exam} close={() => {
                        close()
                        loadChoices()
                    }}/> : "Bitte zuerst Frage speichern."
                }
            </MyModal>
        </LightContainer>
        {
            scores && <>
                <h3>Statistik</h3>
                <table>
                    <tr>
                        <th>Wert</th>
                        <th>Anteil</th>
                        <th>Absolut</th>
                    </tr>
                    {
                        Object.entries(scores).map(([points, data]) => <tr>
                            <td>{exam.type.value === ExamTypeMap.selfservice ? (points === 10 ? "richtig" : "falsch") : points / 10}</td>
                            <td>{Math.round(data.percentage * 100)} %</td>
                            <td>{data.count}</td>
                        </tr>)
                    }
                </table>
                <br/>
                <br/>
            </>
        }
        {
            state.question.ID && <UserHistory userID={state.question.ID} personType={"question"}/>
        }

    </Container>
}

function AddChoice({question, exam, close}) {
    const [{error, loading}, setStatusVar] = useState({})
    const [state, setState] = useState({})

    return <Container name={"Antwortmöglichkeit hinzufügen"}>
        <Status text={error} type={"error"}/>
        <FormContextWrapper value={{state, setState}} onSubmit={() => maxiPost(`/exam/question/${question.ID}/addChoice`, state, {setStatusVar}).then(close)}>

            {

                exam?.type.value === ExamTypeMap.selfservice && <>
                    <TextfieldInput name={"Text"} tag={"text"}/>
                    <br/>
                    <CheckboxInput tag={"correct"} name={"Richtig?"}/>
                    <br/>
                </>
            }
            {

                exam?.type.value === ExamTypeMap.synchronous && <>
                    <TextfieldInput name={"A-Note (mehrere Werte durch ; getrennt)"} ph={"2;4"} tag={"data_a1"} labelWidth={350} width={80}/>
                    <TextfieldInput name={"B-Note-Intervall (+-0,5 Abweichung erlaubt)"} ph={"3;4"} tag={"data_b1"} labelWidth={350} width={80}/>
                    <TextfieldInput name={"Neutral (mehrere Werte durch ; getrennt)"} ph={"1;3"} tag={"data_neutral"} labelWidth={350} width={80}/>
                    {
                        (question.topic?.value === QuestionTopicMap.sprung || question.topic?.value === QuestionTopicMap.tramp) && <>
                            <TextfieldInput name={"A-Note 2 (mehrere Werte durch ; getrennt)"} ph={"2;4"} tag={"data_a2"} labelWidth={350} width={80}/>
                            <TextfieldInput name={"B-Note 2-Intervall (+-0,5 Abweichung erlaubt)"} ph={"3;4"} tag={"data_b2"} labelWidth={350} width={80}/>
                            <TextfieldInput name={"Neutral 2 (mehrere Werte durch ; getrennt)"} ph={"1;3"} tag={"data_neutral2"} labelWidth={350} width={80}/>
                        </>
                    }


                    <br/>
                </>
            }
            <MaxBtn>Hinzufügen</MaxBtn> <Loader loading={loading}/>
        </FormContextWrapper>
    </Container>
}